import React, { Fragment } from 'react'
import './partners.scss'
import DHF from '../../assets/Partners/DaisyHF.png';
import FL from '../../assets/Partners/FutureLife.jpg';
import J from '../../assets/Partners/Jungle.png';
import PnP from '../../assets/Partners/Pick.png';
import AFW from '../../assets/Partners/afsaWhite.png';
import S from '../../assets/Partners/Shoprite.png';
import C from '../../assets/Partners/Checkers.png';
import B from '../../assets/Partners/buttanut.png';
import { Box, styled } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navbar from "./navbar";
import Footer from "./footer";

interface Link {
  url: string;
  title: string;
}


interface ArrowProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
  }
  
  function Arrows(props: ArrowProps) {
    const { className, style, onClick } = props;
  
    return (
      <div
        className={className}
        style={{ ...style, display: "block" , color:"green"}}
        onClick={onClick}
      />
    );
  }
  export default function Partners(this: any) {
    const [isHovered, setIsHovered] = React.useState(false);
    
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const lnks: Link[] = [
      { url: "/", title: "Home" },
      { url: "/About", title: "About" },
      { url: "/explore", title: "Explore Allegrow and Where To Start" },
      // { url: "/Services", title: "Services" },
      { url: "/", title: "Allergy Information" },
      { url: "/partners", title: "Partners" },
      { url: "/form", title: "Contact" },
    ];
  
  
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: true,
      nextArrow: <Arrows />,
      prevArrow: <Arrows />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 930,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 620,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 4000,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    };
  
    const teamMembers = [
      {
        name: 'AFSA',
        image: AFW,
        link:'https://www.allergyfoundation.co.za/',
      },
      {
        name: 'Pick ‘n Pay',
        image: PnP,
        link:'https://www.pnp.co.za/',
      },
      {
        name: 'Checkers',
        image: C,
        link:'https://www.checkers.co.za/',
      },
      {
        name: 'Daisy Health Food',
        image: DHF,
        link:'https://www.daisyhealth.co.za/',
      },
      {
        name: 'Future Life',
        image: FL,
        link:'https://futurelife.com/',
      },
        {
          name: 'ButtaNutt',
          image: B,
          link:'https://www.buttanutt.co.za/',
        },
    ];
  
    return (
      <>
        <Navbar navbarLinks={lnks} />
        <Box
          sx={{
            pt: "0px",
            height: "calc(100vh - 150px)",
            marginBottom: 10,
            flex: 1,
          }}
          className="partnersContainer"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              pt: 5,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2
                style={{
                  fontFamily: "margarosa",
                  marginBottom: 0,
                  fontSize: "2em",
                }}
              >
                Our Partners
              </h2>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              pt: 0,
              alignItems: "center",
              justifyContent: "center",
              fontSize: "20px",
              textAlign: "justify",
            }}
          >
            <p style={{ width: "80%" }}>
              The Allegrow Team would like to thank the following companies, for
              partnering with us in improving the quality of life of those
              living with food allergies –and intolerances, by assisting us
              through various means.
            </p>
          </Box>
          <Box
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          >
            <Carousel>
              <Slider {...settings}>
                {teamMembers.map((member, index) => (
                  <div
                    className={`carousel-item ${isHovered ? "hovered" : ""}`}
                    key={index}
                    onClick={() => {
                      window.open(member.link, "_blank");
                    }}
                  >
                    <div className="centered-content">
                      <img
                        src={member.image}
                        alt={member.name}
                        className="partner__member__image"
                      />
                    </div>
                    <h3>{member.name}</h3>
                  </div>
                ))}
              </Slider>
            </Carousel>
          </Box>
        </Box>
        <Footer />
      </>
      
    );
  };
  
  const Carousel = styled("div")(({ theme }) => ({
    width: "100%",
    height: "110%",
  }));
  

import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, InputLabel } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import "./subCategory.scss";
import { CategoryAPI, SubCategoryAPI } from '../../api/services';

interface TextModalProps {
    open: boolean;
    onClose: () => void;
}

interface MyDropdownProps { label: string; value: number }

const AddSubCategoryModal: React.FC<TextModalProps> = ({ open, onClose }) => {
    const [name, setName] = useState<string>('');
    const [isAddLoading, setisAddLoading] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState(0);
    const [options, setOptions] = React.useState<MyDropdownProps[]>();

    const handleChange = (event: SelectChangeEvent<number>) => {
        setSelectedValue(event.target.value as number);
        console.log(event.target.value)
    };

    const handleSave = (name: string,selectedValue: number) => {
        if (name === '') {
            toast.error("Name can't be empty!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setisAddLoading(false);
        } else {
            const loggedInId = JSON.parse(String(localStorage.getItem('formData')))["id"];
            let data = {
                "name": name,
                "category_id": selectedValue,
                "created_by": Number(loggedInId),
                "updated_by": Number(loggedInId)
            }
            SubCategoryAPI.addSubCategory(data).then((res) => {
                setName('');
                setSelectedValue(0);
                setisAddLoading(false);
                toast.success("SubCategory Added Successfully!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                onClose();
            }).catch(() => {
                toast.error("SubCategory already exist!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setisAddLoading(false);
            })
        }

    };

    React.useEffect(() => {
        const templist: { label: string; value: number }[] = new Array();
        CategoryAPI.getAllCategory().then(async (response) => {
            response["data"]
                .sort((a: { name: string }, b: { name: string }) =>
                    a.name.localeCompare(b.name)
                )
                .forEach((element: { name: string; id: number; is_deleted: boolean }) => {
                    if (!element.is_deleted) {
                        templist.push({ label: element.name, value: element.id });
                    }
                });
            setOptions(templist)
        });
    }, [])
    return (
        <Dialog open={open} onClose={onClose} className="text-modal-dialog" style={{ borderRadius: 20 }}>
            <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>Add Food SubCategory</DialogTitle>
            <DialogContent>
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    InputProps={{
                        disableUnderline: true, // <== added this
                    }}
                    placeholder="Name"
                    className='addText'
                    onChange={(e) => setName(e.currentTarget.value)}
                    value={name}
                />
                <InputLabel style={{ marginTop: 10 }}>Select a food category</InputLabel>
                <Select
                    value={selectedValue}
                    onChange={handleChange}
                    displayEmpty={true}
                    inputProps={{ 'aria-label': 'Select an option' }}
                    className='drop-down'
                >
                    <MenuItem value="">Select an option</MenuItem>
                    {options?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {isAddLoading ?
                    (
                        <Button><CircularProgress size={20} style={{ color: '#70a132' }} /></Button>
                    ) : (
                        <Button onClick={() => {
                            setisAddLoading(true);
                            handleSave(name, selectedValue)
                        }}>Save</Button>
                    )}
            </DialogActions>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Dialog >

    );
};

export default AddSubCategoryModal;

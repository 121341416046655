import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './login.scss';
import logo from '../../assets/logo/logo.png';
import { UsersAPI } from '../../api/services';


const LoginPage: React.FC = () => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    setLoading(true);
    if(data['email'] !== 'admin@allegrow.co.za'){
      toast.error("Unauthorized", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }else{
      UsersAPI.login(data).then(async (res: any) => {
      
        if(res.data.is_admin){
          localStorage.setItem('formData', JSON.stringify(res.data));
        await new Promise((resolve) => setTimeout(resolve, 2000));
  
        toast.success("Logged In!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
  
        setLoading(false)
        navigate('/Home')
        }else{
          toast.error("Unauthorized", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
  
      }).catch((error: any) => {
        toast.error("Invalid Credentials!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false)
      })
    }
    
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>{'AlleGrow-Login'}</title>
      </Helmet>
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo-style" />
      </div>
      <h2>Private Admin Portal Login</h2>
      <form className="login-form">
        <div className="form-group">
          <input type="email" onChange={(e) => setEmail(e.currentTarget.value)} placeholder="Email" className="input-field" />
        </div>
        <div className="form-group">
          <input type={showPassword ? 'text' : 'password'} onChange={(e) => setPassword(e.currentTarget.value)} placeholder="Password" className="input-field" />
        </div>
        <div className="form-group">
          {!loading ? (
            <button type="button" onClick={() => onSubmit({ email, password })} className="login-button">Login</button>
          ) : (
            <button type="button" className="login-button">
              <CircularProgress
                size={24}
                style={{
                  // position: 'absolute',
                  color: "white",
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            </button>
          )}

        </div>
        <div className="form-group">
        <button type="button" onClick={() => setShowPassword(!showPassword)} className="login-button frg-pass">{showPassword ? 'Hide' : 'Show'} Password</button>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default LoginPage;
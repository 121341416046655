import * as React from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoneIcon from '@mui/icons-material/Done';
import Close from "@mui/icons-material/Close";
import DeleteForever from "@mui/icons-material/DeleteForever";
import {
  DataGrid,
} from "@mui/x-data-grid";
import SideNavigation from "../../navigation/SideNavigation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import "./product.scss";
import { store } from "../../types/store";
import { BrandAPI, ProductAPI, StoreAPI } from "../../api/services";
import BackupIcon from '@mui/icons-material/Backup';
import { url } from '../../api/constants';
import { toast, ToastContainer } from "react-toastify";
import RecyclingIcon from '@mui/icons-material/Recycling';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
// import TextModal from "./addBrand";
// import EditModal from "./editBrand";


export default function ProductScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLogged, setIsLogged] = React.useState(false)
  const [isAddModalOpen, setAddModalOpen] = React.useState(false);
  const [isEditModalOpen, setEditModalOpen] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState(false);
  const [deleteDailog, setDeleteDailog] = React.useState(false);
  const [deleteReason, setDeleteReason] = React.useState('')
  const [isOpenActivate, setIsOpenActivate] = React.useState(false);
  const [isDeleted, setIsDeleted] = React.useState({
    id: 0,
    is_deleted: false
  });
  const [imageConvertShow, setImageConvertShow] = React.useState(false);
  const [imageBase64, setImageBase64] = React.useState<any>(null);
  const [uploadShow, setUploadShow] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState(null);
  const [data, setData] = React.useState<any[]>([])
  const [fileProcess, setFileProcess] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = React.useState(false);

  React.useEffect(() => {
    if (!localStorage.getItem('formData')) {
      navigate('/Login')
    } else {
      setIsLogged(true)
      ProductAPI.getAllProductsView().then((res) => {
        setData(res.data);
        setIsLoading(false)
      })
    }


  }, [isAddModalOpen, isOpenDelete, isEditModalOpen, isOpenActivate])

  const deleteProduct = (isDeleted: any) => {
    const deletdId = isDeleted.id;
    const data = { is_deleted: isDeleted.is_deleted }
    ProductAPI.updateProduct(data, deletdId).then((res) => {
      setIsOpenDelete(false)
      setIsOpenActivate(false)
    })
  }

  const removeProduct = () => {
    if (deleteReason == '') {
      toast.warn("Enter the reason for deleting the product.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setDeleteLoader(true)
      const deletdId = isDeleted.id;
      const data = { product_id: deletdId, reason: deleteReason }
      ProductAPI.deleteProduct(data).then((res) => {
        setDeleteDailog(false)
        setDeleteReason('')
        toast.success("Product Have Been Deleted!", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }).then(() => {
        setDeleteLoader(false)
        setIsLogged(true)
        ProductAPI.getAllProducts().then((res) => {
          setData(res.data);
          setIsLoading(false)
        })
      })
    }
  }

  const handleImageConvert = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      // const reader = new FileReader();
      // reader.onload = (event) => {
      //   const base64: any = event.target?.result as string | undefined;
      //   setImageBase64(base64.replace(/^data:image\/\w+;base64,/, ''))
      // };
      // reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append('image', file);
      try {
        // Send the FormData to your Django API endpoint
        ProductAPI.resizeImage(formData).then((res) => {
          setImageBase64(res.data.resized_base64)
        })
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(imageBase64);
    } catch (error) {
      console.error('Copy failed:', error);
    }
  };

  const columns: any = [
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params: any) => {
        const onViewClick = (e: any) => {
          console.log(params.row)
          let data = params.row;
          navigate('/AddProduct', { state: { data } });
        };

        return (
          <>
            <Button
              variant="contained"
              sx={{ m: 0, color: "#fff", backgroundColor: "#70a132", width: 30 }}
              onClick={onViewClick}
            >
              <ModeEditIcon style={{ fontSize: 23 }} />
            </Button>

          </>
        );
      },
    },
    { field: "name", headerName: "Name", width: 300 },
    { field: "description", headerName: "Description", width: 300 },
    { field: "brand", headerName: "Brand", width: 300 },
    {
      field: "is_delete", headerName: "Is Active", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            {
              params.row.is_deleted ?
                (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                      setIsDeleted({ id: params.row.id, is_deleted: false });
                      if (params.row.is_deleted) {
                        setIsOpenActivate(true);
                      } else {
                        setIsOpenDelete(true);
                      }
                    }}
                  >
                    <Close style={{ fontSize: 23 }} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                      setIsDeleted({ id: params.row.id, is_deleted: true });
                      if (params.row.is_deleted) {
                        setIsOpenActivate(true);
                      } else {
                        setIsOpenDelete(true);
                      }
                    }}
                  >
                    <DoneIcon style={{ fontSize: 23 }} />
                  </Button>
                )}
          </>);
      }
    },
    {
      field: "image",
      headerName: "Has Image",
      width: 100,
      headerClassName: "custom-header",
      renderHeader: (params: any) => {
        <div style={{ fontWeight: "bold" }}>{params.colDef.headerName}</div>
      },
      renderCell: (params: any) => {
        // Display the symbol based on the value
        if (params.row.image) {
          return (
            <span style={{ cursor: "pointer" }}>
              ✔️
            </span>
          );
        } else {
          return (
            <span style={{ cursor: "pointer" }}>
              ❌
            </span>
          );
        }
      },
    },
    {
      field: "delete", headerName: "Delete", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              variant="contained"
              sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
              onClick={() => {
                setIsDeleted({ id: params.row.id, is_deleted: false });
                setDeleteDailog(true);
              }}
            >
              <DeleteForever style={{ fontSize: 23 }} />
            </Button>
          </>);
      }
    },

  ];
  return (
    <>
      {isLogged ? (
        <div id="home-container">
          <Helmet>
            <title>{"AlleGrow-Product"}</title>
          </Helmet>
          <div>
            <SideNavigation />
          </div>
          <div className="child">
            <div className="header">
              <p
                style={{
                  fontSize: 25,
                  fontWeight: 500,
                  color: "#70a132",
                  marginTop: 80,
                }}
              >
                Products
              </p>
            </div>
            <div className="tableheader">
              <Button
                variant="contained"
                sx={{
                  color: "#70a132",
                  backgroundColor: "#fff",
                  width: 25,
                  fontSize: 15,
                  height: 30,
                }}
                onClick={() => {
                  navigate("/AddProduct");
                }}
              >
                Add
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "#70a132",
                  backgroundColor: "#fff",
                  width: "125px !important",
                  fontSize: 15,
                  height: 30,
                  marginLeft: 2,
                }}
                onClick={() => {
                  window.open(url + "product/upload/");
                }}
              >
                <DownloadIcon sx={{ marginRight: 0.5 }} />
                Template
              </Button>
              {/* <Button
                variant="contained"
                sx={{
                  color: "#70a132",
                  backgroundColor: "#fff",
                  width: "125px !important",
                  fontSize: 15,
                  height: 30,
                  marginLeft: 2,
                }}
                onClick={() => {
                  setUploadShow(true);
                }}
              >
                <BackupIcon sx={{ marginRight: 0.5 }} />
                Upload
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "#70a132",
                  backgroundColor: "#fff",
                  width: "195px !important",
                  fontSize: 15,
                  height: 30,
                  marginLeft: 2,
                }}
                onClick={() => {
                  setImageConvertShow(true);
                }}
              >
                <RecyclingIcon sx={{ marginRight: 0.5 }} />
                Convert Image
              </Button> */}
            </div>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  size={50}
                  style={{ marginTop: 50, color: "#70a132" }}
                />
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <DataGrid
                  style={{
                    maxHeight: "100%",
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                  rows={data}
                  columns={columns}
                  getRowId={(row) => row.id}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  rowSelection={false}
                />
              </div>
            )}
          </div>
          <Dialog
            open={isOpenDelete}
            onClose={() => setIsOpenDelete(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Deactivate Product"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to deactivate this product?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => deleteProduct(isDeleted)}>Yes</Button>
              <Button onClick={() => setIsOpenDelete(false)} autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={deleteDailog}
            onClose={() => setDeleteDailog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete Product"}
            </DialogTitle>
            <DialogContent style={{ marginRight: 20, marginLeft: 20 }}>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this product?
              </DialogContentText>
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="reason"
                name="reason"
                InputProps={{
                  disableUnderline: true, // <== added this
                }}
                placeholder="Enter the reason for deleting the product."
                style={{
                  marginTop: '30px',
                  border: 0,
                  borderBottom: "2px solid #70a132",
                  marginBottom: '10px',
                  paddingLeft: 5
                }}
                onChange={(e) => setDeleteReason(e.currentTarget.value)}
                value={deleteReason}
              />
            </DialogContent>
            <DialogActions>
              {deleteLoader ? (
                <CircularProgress size={20} />
              ) : (
                <Button onClick={() => removeProduct()}>Yes</Button>
              )}

              <Button onClick={() => setDeleteDailog(false)} autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={isOpenActivate}
            onClose={() => setIsOpenActivate(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Activate Product"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to activate this product?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => deleteProduct(isDeleted)}>Yes</Button>
              <Button onClick={() => setIsOpenActivate(false)} autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={uploadShow}
            onClose={() => setUploadShow(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Upload Product File"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <input
                  type="file"
                  onChange={(e: any) => {
                    setUploadFile(e.target.files[0]);
                  }}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  var formData = new FormData();
                  if (uploadFile !== null) {
                    formData.append("file", uploadFile);
                    setFileProcess(true);
                    ProductAPI.bulkUpload(formData).then(() => {
                      setFileProcess(false)
                      setUploadShow(false);
                      setIsLoading(true);
                      toast.success("Products Have Been Processed!", {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                      ProductAPI.getAllProducts().then((res) => {
                        setData(res.data);
                        setIsLoading(false)
                      })
                    }).catch(() => {
                      setFileProcess(false)
                      toast.error("An error occured", {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    })
                  } else {
                    toast.error("No File Selected", {
                      position: "top-right",
                      autoClose: 1500,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                  }


                }}
              >
                {fileProcess ? <CircularProgress /> : 'Upload'}
              </Button>
              {!fileProcess && <Button onClick={() => setUploadShow(false)} autoFocus>
                Cancel
              </Button>}
            </DialogActions>
          </Dialog>
          <Dialog
            open={imageConvertShow}
            onClose={() => {
              setImageConvertShow(false)
              setImageBase64(null);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Convert Product Image"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <input
                  type="file"
                  onChange={(e) => handleImageConvert(e)}
                />
              </DialogContentText>
              {imageBase64 !== null && (
                <>
                  <DialogContentText style={{ marginTop: 15 }} id="alert-dialog-description">
                    Please click the button to copy the following text in your product's image column:
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description" style={{ marginTop: 15 }}>
                    {imageBase64.substring(0, 35)}...
                  </DialogContentText>
                  <Button sx={{ backgroundColor: '#70a132', color: 'white', marginTop: 1 }} onClick={() => {
                    handleCopyClick()
                  }} autoFocus>
                    <ContentPasteGoIcon sx={{ marginRight: 1 }} /> Copy To ClipBoard
                  </Button>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setImageConvertShow(false);
                setImageBase64(null);
              }} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <CircularProgress />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
import * as React from "react";
import {
  CircularProgress,
  Box,
  Typography,
  Select,
  SelectChangeEvent,
  FormControl,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  Snackbar,
  ListItemText,
  Checkbox,
  OutlinedInput,
  Chip,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SideNavigation from "../../navigation/SideNavigation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ProductDetails } from "../../types/product";
import { ingredient } from "../../types/ingredient";
import uploadIcon from "../../assets/gallery.png";
import { ToastContainer, toast } from "react-toastify";
import "./product.scss";
import {
  BrandAPI,
  StoreAPI,
  CategoryAPI,
  SubCategoryAPI,
  IngredientAPI,
  ProductAPI,
  AllergyAPI,
  SyncIngredients,
} from "../../api/services";
import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import { store } from "../../types/store";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as XLSX from "xlsx";

import { url } from "../../api/constants";


const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#70a132",
      dark: "#70a132",
    },
  },
});

export default function AddProductScreen() {

  const location = useLocation();
  const paramData = location.state?.data; // Must not be undefined

  

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [image, setImage] = React.useState<string | ArrayBuffer | null>(null);
  const [brands, setBrands] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [stores, setStores] = React.useState<store[]>([]);
  const [ingredients, setIngredients] = React.useState<ingredient[]>([]);
  const [mayContains, setMayContains] = React.useState<any>([])
  const loggedInId = JSON.parse(String(localStorage.getItem("formData")))["id"];
  const [productDetails, setProductDetails] = React.useState<any>({
    name: "",
    created_by: Number(loggedInId),
    updated_by: Number(loggedInId),
    image: null,
    brand_id: 0,
    category_id: 0,
    subcategory_id: 0,
    description: "",
    ingredients: [],
    stores: [],
    mayContain: [],
  });

  React.useEffect(() => {
    if (paramData) {
      console.warn("------------------------\n", paramData)
      ProductAPI.getProductById(paramData.id).then((res) => {
        setImage(res.data.image);
        setProductDetails(res.data);
      })
    }
  }, [paramData]);


  //Empty field error states
  const [emptyNameField, setEmptyNameField] = React.useState(false);
  const [emptyDescriptionField, setEmptyDescriptionField] =
    React.useState(false);
  const [emptyBrand, setEmptyBrand] = React.useState(false);
  const [emptyCategory, setEmptyCategory] = React.useState(false);
  const [emptySubCategory, setEmptySubCategory] = React.useState(false);
  const [emptyStores, setEmptyStores] = React.useState(false);
  const [emptyIngredients, setEmptyIngredients] = React.useState(false);
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<any>(null);


  const getIngredientDropdown = () => {
    setIsLoading(true)
    IngredientAPI.getIngredientsOnly().then((res) => {
      setIngredients(res.data);
    }).finally(() => setIsLoading(false))
  }

  function handleFileChangeExcel(event: any) {
    setSelectedFile(event.target.files[0]);
  }

  const fetchProductPreviewData = async () => {
    if (selectedFile) {
      setIsLoading(true);
      setAddModalOpen(false);
      await new Promise((resolve) => setTimeout(resolve, 3000));

      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileContent = event.target?.result; // Use optional chaining to access result
        if (fileContent) {
          //products sheet
          const workbook = XLSX.read(fileContent, { type: "binary" });
          const sheetName = workbook.SheetNames[0]; // Assuming the data is in the second sheet
          const sheet = workbook.Sheets[sheetName];
          const parsedData: any[] = XLSX.utils.sheet_to_json(sheet);

          //ingredients sheet
          const ingredientSheet = workbook.SheetNames[0]; // Assuming the data is in the second sheet
          const ingsheet = workbook.Sheets[ingredientSheet];
          const parsedingData: any[] = XLSX.utils.sheet_to_json(ingsheet);

          await syncIngredients(parsedingData).then(() => {
            // console.log(data);
            toast.success("Ingredients Synced Successfully");

            setIsLoading(false);
          });
        }
      };
      reader.readAsBinaryString(selectedFile);
    }
  };

  const syncIngredients = async (ingredients: any[]) => {
    // console.log(ingredients)

    const formattedIngredientData = ingredients
      .slice(0)
      .map((row: any, index) => ({
        name: row.Name,
        contains: row.Contains,
      }));

    //apis post
    await SyncIngredients.productApprovalSyncIng(formattedIngredientData).then(
      (sync_res) => {
        IngredientAPI.getIngredientsOnly().then((res) => {
          setIngredients(res.data);

          sync_res.data.ingredients.forEach((element: any) => {
            // Check if productDetailsData.ingredients is an array before using indexOf
            if (Array.isArray(productDetails.ingredients)) {
              // Use indexOf method
              const index = productDetails.ingredients.indexOf(element);

              // Check if the element is not already in the array before adding it
              if (index === -1) {
                setProductDetails((prevProductDetails: any) => ({
                  ...prevProductDetails,
                  ingredients: [...prevProductDetails.ingredients, element],
                }));
              }
            } else {
              // If productDetails.ingredients is not an array, initialize it as an array with the current element
              setProductDetails((prevProductDetails: any) => ({
                ...prevProductDetails,
                ingredients: [element],
              }));
            }
          });
        });
        // handleIngredientChange
        return;
      }
    );

    //  console.log(formattedIngredientData);
  };

  //Empty field error messages
  const [emptyNameFieldError, setEmptyNameFieldError] =
    React.useState("Name is required");
  const [emptyDescriptionFieldError, setEmptyDescriptionFieldError] =
    React.useState("Description is required");

  const getProductDropdownOptions = () => {
    BrandAPI.getAllBrands().then((res) => {
      setBrands(res.data);
      CategoryAPI.getAllCategory().then((res) => {
        setCategories(res.data);
        SubCategoryAPI.getAllSubCategory().then((res) => {
          setSubCategories(res.data);
          StoreAPI.getAllStore().then((res) => {
            setStores(res.data);
            IngredientAPI.getIngredientsOnly().then((res) => {
              setIngredients(res.data);
              AllergyAPI.getAllergyContains().then((res) => {
                setMayContains(res.data);
                setIsLoading(false);
              })
              // IngredientAPI.getAllMayContains().then((res) => {
              //   setMayContains(res.data.contains);
              // })
              // setIsLoading(false);
            });
          });
        });
      });
    });
  };

  const handleStoreChange = (event: SelectChangeEvent<typeof stores>) => {
    const {
      target: { value },
    } = event;
    setProductDetails((prevProductDetails: any) => ({
      ...prevProductDetails,
      stores: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleMayContainIngredientChange = (
    event: SelectChangeEvent<typeof stores>
  ) => {
    const {
      target: { value },
    } = event;
    setProductDetails((prevProductDetails: any) => ({
      ...prevProductDetails,
      mayContain: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const getStoreName = (value: any) => {
    let store = stores.filter((store) => store.id === Number(value))[0];
    return store.name;
  };

  const getIngredientName = (value: any) => {
    let ingredient = ingredients.filter((ing) => ing.id === Number(value))[0];
    return ingredient.name;
  };

  const getMayContainName = (value: any) => {
    let contains = mayContains.filter((m: string) => m === value);
    return contains;
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setProductDetails({ ...productDetails, image: file });
    const imageURL = URL.createObjectURL(file);
    setImage(imageURL);
  };

  React.useEffect(() => {
    // setIsLoading(true);
    getProductDropdownOptions();
  }, []);

  const saveNoChanges = () => {
    setIsLoading(true);
    ProductAPI.update_Product_No_Changes(productDetails.id).then(() => {
      navigate("/Products");
    }).catch(() => {
      setIsLoading(false)
      toast.error("An error occured", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    })
  }

  const saveProduct = () => {
    setIsLoading(true);
    if (
      productDetails.name === "" ||
      productDetails.image === null ||
      productDetails.brand_id === 0 ||
      productDetails.category_id === 0 ||
      productDetails.subcategory_id === 0 ||
      productDetails.ingredients.length < 1
    ) {
      setIsLoading(false);
      toast.error("Please fill in all required fields and upload an image!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setEmptyBrand(true);
      setEmptyNameField(true);
      setEmptyCategory(true);
      setEmptySubCategory(true);
      setEmptyIngredients(true);
    } else {
      const formData = new FormData();
      formData.append("name", productDetails.name);
      if (productDetails.image instanceof File) {
        formData.append("image", productDetails.image);
      }

      formData.append("brand_id", productDetails.brand_id.toString());
      formData.append("category_id", productDetails.category_id.toString());
      formData.append(
        "subcategory_id",
        productDetails.subcategory_id.toString()
      );
      formData.append("description", productDetails.description);
      if (paramData) {
        formData.append("id", productDetails.id.toString());
        formData.append("modified_date", new Date().toISOString())
        ProductAPI.update_Product(formData, productDetails.id)
          .then(() => {
            let productId = productDetails.id;
            let stores = [];
            let ingredients: any[] = [];
            let mayContainIngredients: any[] = [];
            for (var item of productDetails.stores) {
              stores.push({
                productId: productId,
                storeId: item,
              });
            }
            for (var item of productDetails.ingredients) {
              ingredients.push({
                productId: productId,
                ingredientId: item,
              });
            }
            for (var item of productDetails.mayContain) {
              mayContainIngredients.push({
                productId: productId,
                maycontain: item,
              });
            }
            ProductAPI.update_ProductStores(stores).then(() => {
              ProductAPI.update_ProductIngredients(ingredients).then(() => {
                ProductAPI.update_ProductMayContainIngredients(
                  mayContainIngredients
                ).then(() => {
                  setIsLoading(false);
                  toast.success("Product Updated Successfully!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                  navigate("/Products");
                });
              });
            });
          })
          .catch(() => {
            setIsLoading(false);
            toast.error("An error occured", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      } else {
        ProductAPI.addProduct(formData)
          .then((res) => {
            let productId = res.data.id;
            let stores = [];
            let ingredients: any[] = [];
            let mayContainIngredients: any[] = [];
            for (var item of productDetails.stores) {
              stores.push({
                productId: productId,
                storeId: item,
              });
            }
            for (var item of productDetails.ingredients) {
              ingredients.push({
                productId: productId,
                ingredientId: item,
              });
            }
            for (var item of productDetails.mayContain) {
              mayContainIngredients.push({
                productId: productId,
                maycontain: item,
              });
            }
            ProductAPI.addProductStores(stores).then(() => {
              ProductAPI.addProductIngredients(ingredients).then(() => {
                ProductAPI.addProductMayContainIngredients(
                  mayContainIngredients
                ).then(() => {
                  setIsLoading(false);
                  toast.success("Product Added Successfully!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                  navigate("/Products");
                });
              });
            });
          })
          .catch(() => {
            setIsLoading(false);
            toast.error("Product already exists!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
    }
  };

  const checkProduct = (name: string) => {
    ProductAPI.getProductsByName(name).then((res) => {
      if (res.data.length > 0) {
        toast.error("Product Already Exists!", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  }


  return (
    <>
      <div id="home-container">
        <Helmet>
          <title>{"AlleGrow-Add Product"}</title>
        </Helmet>
        <div>
          <SideNavigation />
        </div>
        <div className="child">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                marginTop: 120,
              }}
            >
              <CircularProgress
                size={50}
                style={{ marginTop: 50, color: "#70a132" }}
              />
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <div className="header">
                <p
                  style={{
                    fontSize: 25,
                    fontWeight: 500,
                    color: "#70a132",
                    marginTop: 80,
                  }}
                >
                  Add Product
                </p>
              </div>
              <div className="tableheader">
                <Button
                  variant="contained"
                  sx={{
                    color: "#70a132",
                    backgroundColor: "#fff",
                    width: 100,
                    fontSize: 15,
                    height: 30,
                    marginRight: 2,
                  }}
                  onClick={() => setAddModalOpen(true)}
                >
                  Add File
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    color: "#70a132",
                    backgroundColor: "#fff",
                    width: "125px !important",
                    fontSize: 15,
                    height: 30,
                    marginLeft: 2,
                    marginRight: 2,
                  }}
                  onClick={() => {
                    window.open(url + "product_approval/upload/");
                  }}
                >
                  <DownloadIcon sx={{ marginRight: 0.5 }} />
                  Template
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    color: "#70a132",
                    backgroundColor: "#fff",
                    width: "125px !important",
                    fontSize: 15,
                    height: 30,
                    marginLeft: 2,
                  }}
                  onClick={() => {
                    getIngredientDropdown()
                  }}
                >
                  <RefreshIcon sx={{ marginRight: 0.5 }} />
                  Refresh
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    color: "#70a132",
                    backgroundColor: "#fff",
                    width: "145px !important",
                    fontSize: 15,
                    height: 30,
                    marginLeft: 4,
                  }}
                  onClick={() => {
                    saveNoChanges()
                  }}
                >
                  No Changes
                </Button>
              </div>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {emptyNameField ? (
                  <TextField
                    error={emptyNameField}
                    id="outlined-input"
                    label="Product Name"
                    size="small"
                    sx={{
                      m: 0.5,
                      p: 0.5,
                      paddingTop: "10px",
                      "& label": { marginTop: "8px" },
                    }}
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        name: e.currentTarget.value,
                      })
                    }
                    helperText={emptyNameFieldError}
                    value={productDetails.name}
                    onBlur={(e) => {
                      checkProduct(e.currentTarget.value)
                    }}
                  />
                ) : (
                  <TextField
                    id="outlined-input"
                    label="Name"
                    size="small"
                    sx={{
                      m: 0.5,
                      p: 0.5,
                      paddingTop: "10px",
                      "& label": { marginTop: "8px" },
                      "& label.Mui-focused": {
                        color: "#70a132",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#70a132",
                        },
                      },
                    }}
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        name: e.currentTarget.value,
                      })
                    }
                    onBlur={(e) => {
                      checkProduct(e.currentTarget.value)
                    }}
                    value={productDetails.name}
                  />
                )}
                {emptyDescriptionField ? (
                  <TextField
                    id="outlined-input"
                    label="Description"
                    size="small"
                    sx={{
                      m: 0.5,
                      p: 0.5,
                      paddingTop: "10px",
                      "& label": { marginTop: "8px" },
                    }}
                    multiline
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        description: e.currentTarget.value,
                      })
                    }
                    error={emptyDescriptionField}
                    helperText={emptyDescriptionFieldError}
                    value={productDetails.description}
                  />
                ) : (
                  <TextField
                    id="outlined-input"
                    label="Description"
                    size="small"
                    sx={{
                      m: 0.5,
                      p: 0.5,
                      paddingTop: "10px",
                      "& label": { marginTop: "8px" },
                      "& label.Mui-focused": {
                        color: "#70a132",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#70a132",
                        },
                      },
                    }}
                    multiline
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        description: e.currentTarget.value,
                      })
                    }

                    value={productDetails.description}
                  />
                )}
                <ThemeProvider theme={theme}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} error={emptyBrand}>
                    <InputLabel id="test-select-label">Brand</InputLabel>
                    <Select
                      value={
                        productDetails.brand_id === 0
                          ? ""
                          : productDetails.brand_id
                      }
                      onChange={(e) => {
                        setProductDetails({
                          ...productDetails,
                          brand_id: Number(e.target.value),
                        });
                      }}
                      labelId="test-select-label"
                      label="Brands"
                      sx={{
                        paddingTop: "10px",
                        "& label": { marginTop: "8px" },
                        "& label.Mui-focused": {
                          color: "#70a132",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#70a132",
                          },
                        },
                        "&:focus": {
                          color: "green",
                        },
                      }}
                    >
                      {brands.map((x: any) => (
                        <MenuItem value={x.id}>{x.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    error={emptyCategory}
                  >
                    <InputLabel id="test-select-label">
                      Food Category
                    </InputLabel>
                    <Select
                      value={
                        productDetails.category_id === 0
                          ? ""
                          : productDetails.category_id
                      }
                      onChange={(e) => {
                        setProductDetails({
                          ...productDetails,
                          category_id: Number(e.target.value),
                        });
                      }}
                      labelId="test-select-label"
                      label="Brands"
                      sx={{
                        paddingTop: "10px",
                        "& label": { marginTop: "8px" },
                        "& label.Mui-focused": {
                          color: "#70a132",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            color: "#70a132",
                          },
                        },
                        "&:focus": {
                          color: "green",
                        },
                      }}
                    >
                      {categories.map((x: any) => (
                        <MenuItem value={x.id}>{x.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {productDetails.category_id !== 0 && (
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      error={emptySubCategory}
                    >
                      <InputLabel id="test-select-label">
                        Food SubCategory
                      </InputLabel>
                      <Select
                        value={
                          productDetails.subcategory_id === 0
                            ? ""
                            : productDetails.subcategory_id
                        }
                        onChange={(e) => {
                          setProductDetails({
                            ...productDetails,
                            subcategory_id: Number(e.target.value),
                          });
                        }}
                        labelId="test-select-label"
                        label="Brands"
                        sx={{
                          paddingTop: "10px",
                          "& label": { marginTop: "8px" },
                          "& label.Mui-focused": {
                            color: "#70a132",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              color: "#70a132",
                            },
                          },
                          "&:focus": {
                            color: "green",
                          },
                        }}
                      >
                        {subCategories
                          .filter(
                            (cat: any) =>
                              cat.category_id_id === productDetails.category_id
                          )
                          .map((x: any) => (
                            <MenuItem value={x.id}>{x.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}

                  <FormControl sx={{ m: 1, minWidth: 120 }} error={emptyStores}>
                    <InputLabel id="test-select-label">Stores</InputLabel>
                    <Select
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={getStoreName(value)} />
                          ))}
                        </Box>
                      )}
                      multiple
                      value={productDetails.stores as never[]}
                      onChange={handleStoreChange}
                      labelId="test-select-label"
                      label="Brands"
                      sx={{
                        paddingTop: "10px",
                        "& label": { marginTop: "8px" },
                      }}
                    >
                      {stores.map((x: any) => (
                        <MenuItem value={x.id}>
                          <Checkbox
                            checked={productDetails.stores.indexOf(x.id) > -1}
                          />
                          {x.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    {/* <InputLabel id="ingredients-label">Ingredients</InputLabel> */}
                    <Autocomplete
                      multiple
                      id="ingredients"
                      options={ingredients.map((x: any) => x.name)}
                      value={productDetails.ingredients.map((ingredientId: any) =>
                        getIngredientName(ingredientId)
                      )}
                      onChange={(_, newValue) => {
                        const selectedIngredientIds = newValue.map((ingredientName: any) =>
                          ingredients.find((x: any) => x.name === ingredientName)?.id
                        );
                        setProductDetails((prevProductDetails: any) => ({
                          ...prevProductDetails,
                          ingredients: selectedIngredientIds,
                        }));
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Ingredients"
                          placeholder="Search Ingredients"
                        />
                      )}
                    />
                  </FormControl>

                  {/* Drag Code */}
                  {/* <div className={`${productDetails.ingredients.length === 0 ? '' : 'ItemList'}`}  ref={provided.innerRef} {...provided.droppableProps}> new style*/}
                  {/* <div className="ItemList"  ref={provided.innerRef} {...provided.droppableProps}>  old style*/}

                  {/* <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="ItemList" type="group">
                    {(provided) => (<div className={`${productDetails.ingredients.length === 0 ? '' : 'ItemList'}`}  ref={provided.innerRef} {...provided.droppableProps}>
                    {productDetails.ingredients.map((x:any,index:number) => (
                      <Draggable draggableId={index.toString()} index={index} key={index}>

                      {(provided) => (<div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}><Chip
                      sx={{
                        m:0.5
                      }}
                    key={index}
                    label={
                      `${index+1 +'. '+ getIngredientName(x)}`
                    }

                  />
                  </div> )}
                   
                  </Draggable>
                  ))}
                  {provided.placeholder}
                  </div>)}
                  {/* <div>
                    {productDetails.ingredients.map((x:any,index:number) => (
                    <Chip
                    key={x}
                    label={
                      `${getIngredientName(x)}`
                    }
                  />
                  ))}
                  </div> */}
                  {/* </Droppable> */}
                  {/* </DragDropContext> */}


                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="test-select-label">
                      May Contain Ingredients
                    </InputLabel>
                    <Select
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value, idx) => (
                            <Chip
                              key={value}
                              label={
                                `${getMayContainName(value)}`
                              }
                            />
                          ))}
                        </Box>
                      )}
                      multiple
                      value={productDetails.mayContain as never[]}
                      onChange={handleMayContainIngredientChange}
                      labelId="test-select-label"
                      label="May Contains"
                      sx={{
                        paddingTop: "10px",
                        "& label": { marginTop: "8px" },
                      }}
                    >
                      {mayContains.map((x: any) => (
                        <MenuItem value={x}>
                          <Checkbox
                            checked={
                              productDetails.mayContain.indexOf(x) > -1
                            }
                          />
                          {/* {productDetails.mayContain.indexOf(x) > -1 && (
                            <Typography>{`${productDetails.mayContain.indexOf(x) + 1}.`}</Typography>
                          )} */}

                          {` ${x}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </ThemeProvider>
              </Box>
              <div className="file-upload">
                <img
                  style={{ width: "15%", height: "15%" }}
                  src={image === null ? uploadIcon : image}
                  alt="upload"
                />
                <h3>Click box to upload</h3>
                <p>Maximun file size 10mb</p>
                <input type="file" onChange={handleFileChange} />
              </div>
              <div className="tableheader">
                <Button
                  variant="contained"
                  sx={{
                    color: "#70a132",
                    backgroundColor: "#fff",
                    width: 25,
                    fontSize: 15,
                    height: 30,
                  }}
                  onClick={() => {
                    saveProduct();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Excel Data File"}
        </DialogTitle>
        <DialogContent>
          <input type="file" onChange={(e) => handleFileChangeExcel(e)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddModalOpen(false)} autoFocus>
            Cancel
          </Button>
          <Button onClick={fetchProductPreviewData} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

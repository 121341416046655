import * as React from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoneIcon from '@mui/icons-material/Done';
import Close from "@mui/icons-material/Close";
import {
  DataGrid,
} from "@mui/x-data-grid";
import SideNavigation from "../../navigation/SideNavigation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import "./notPermittedBrand.scss";
import { store } from "../../types/store";
import { NotPermittedBrandAPI, ProductAPI, StoreAPI } from "../../api/services";
import AddBrandModal from "./addNotPermittedBrand";
import EditModal from "./editNotPermittedBrand";
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';


export default function NotPermittedBrandScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLogged, setIsLogged] = React.useState(false)
  const [isAddModalOpen, setAddModalOpen] = React.useState(false);
  const [isEditModalOpen, setEditModalOpen] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState(false);
  const [isOpenActivate, setIsOpenActivate] = React.useState(false);
  const [brandProducts, setBrandProducts] = React.useState<any>([])
  const [isOpenRemove, setIsOpenRemove] = React.useState(false);
  const [selectedBrand, setSelectedBrand] = React.useState<any>({})
  const [specialKey, setSpecialKey] = React.useState('')
  const [isDeleted, setIsDeleted] = React.useState({
    id: 0,
    is_deleted: false
  });
  const [isEditRow, setIsEditRow] = React.useState<store>({
    id: 0,
    name: '',
    is_deleted: false,
    created_by: 0,
    updated_by: 0,
    created_date: new Date,
    modified_date: new Date,
  })

  const [BrandData, setBrandData] = React.useState<store[]>([])


  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  React.useEffect(() => {
    if (!localStorage.getItem('formData')) {
      navigate('/Login')
    } else {
      setIsLogged(true)
      NotPermittedBrandAPI.getAllBrands().then((res) => {
        setBrandData(res.data);
        setIsLoading(false)
      })
    }


  }, [isAddModalOpen, isOpenDelete, isEditModalOpen, isOpenActivate])

  const deleteStore = (isDeleted: any) => {
    const deletdId = isDeleted.id;
    const data = { is_deleted: isDeleted.is_deleted }
    NotPermittedBrandAPI.updateBrand(data, deletdId).then((res) => {
      setIsOpenDelete(false)
      setIsOpenActivate(false)
    })
  }

  const getProductsForStore = () => {
    if (Object.keys(selectedBrand).length > 1) {
      ProductAPI.getProductsByBrand(selectedBrand.id).then((res) => {
        setBrandProducts(res.data);
      })
    }

  }

  React.useEffect(() => {
    getProductsForStore();
  }, [selectedBrand])

  const removeBrand = (id : any) => { 
    NotPermittedBrandAPI.deleteBrand(id).then((res) => {
      NotPermittedBrandAPI.getAllBrands().then((res) => {
        setBrandData(res.data);
        setIsLoading(false)
      })
      toast.success("Brand Deleted", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    })
  }

  const columns: any = [
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params: any) => {
        const onViewClick = (e: any) => {
          setIsEditRow(params.row);
          setEditModalOpen(true);
        };

        return (
          <>
            <Button
              variant="contained"
              sx={{ m: 0, color: "#fff", backgroundColor: "#70a132", width: 30 }}
              onClick={onViewClick}
            >
              <ModeEditIcon style={{ fontSize: 23 }} />
            </Button>

          </>
        );
      },
    },
    { field: "name", headerName: "Name", width: 300 },
    {
      field: "is_delete", headerName: "Is Active", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            {
              params.row.is_deleted ?
                (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                      setIsDeleted({ id: params.row.id, is_deleted: false });
                      if (!params.row.is_deleted) {
                        setIsOpenDelete(true);
                      } else {
                        setIsOpenActivate(true)
                      }

                    }}
                  >
                    <Close style={{ fontSize: 23 }} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                      setIsDeleted({ id: params.row.id, is_deleted: true });
                      setIsOpenDelete(true);
                    }}
                  >
                    <DoneIcon style={{ fontSize: 23 }} />
                  </Button>
                )}
          </>);
      }
    },
    {
      field: "", headerName: "Delete", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              variant="contained"
              sx={{
                m: 1,
                color: "#fff",
                backgroundColor: "red",
                width: 30,
                "&:hover": {
                  backgroundColor: "red !important", // Set the background color on hover
                },
              }}
              onClick={() => {
                setSelectedBrand(params.row)
                removeBrand(params.row.id)
              }}
            >
              <DeleteIcon style={{ fontSize: 23 }} />
            </Button>
          </>
        );
      }
    },
  ];
  return (
    <>
      {isLogged ? (<div id="home-container">
        <Helmet>
          <title>{'AlleGrow-Not Permitted Brands'}</title>
        </Helmet>
        <div>
          <SideNavigation />
        </div>
        <div className="child">
          <div className="header">
            <p style={{ fontSize: 25, fontWeight: 500, color: "#70a132", marginTop: 80 }}>Not Permitted Brands</p></div>
          <div className="tableheader">

            <Button
              variant="contained"
              sx={{ color: "#70a132", backgroundColor: "#fff", width: 25, fontSize: 15, height: 30 }}
              onClick={openAddModal}
            >Add
            </Button>
          </div>
          {isLoading ?
            (
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <CircularProgress size={50} style={{ marginTop: 50, color: '#70a132' }} />
              </div>
            ) : (<div style={{ width: "100%" }}>
              <DataGrid
                style={{ maxHeight: "100%", paddingLeft: 15, paddingRight: 15 }}
                rows={BrandData}
                columns={columns}
                getRowId={row => row.id}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                rowSelection={false}
              />
            </div>)}
        </div>
        <Dialog
          open={isOpenDelete}
          onClose={() => setIsOpenDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Deactivate Brand"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to deactivate this brand?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteStore(isDeleted)}>Yes</Button>
            <Button onClick={() => setIsOpenDelete(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isOpenActivate}
          onClose={() => setIsOpenActivate(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Activate Brand"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to activate this brand?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteStore(isDeleted)}>Yes</Button>
            <Button onClick={() => setIsOpenActivate(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isOpenRemove}
          onClose={() => setIsOpenRemove(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Brand"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please enter the special key to remove the brand:
            </DialogContentText>
            {brandProducts.length < 1 && (
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="name"
                name="name"
                InputProps={{
                  disableUnderline: true, // <== added this
                }}
                placeholder="Key"
                className='addText'
                onChange={(e) => setSpecialKey(e.currentTarget.value)}
              />
            )}

            <DialogContentText id="alert-dialog-description" style={{ fontWeight: 'bold' }}>
              The following products contain this ingredient:
            </DialogContentText>
            {brandProducts.map((x: any, idx: any) => {
              return (
                <DialogContentText id="alert-dialog-description">
                  <ul>
                    <li>
                      {x.name}
                    </li>
                  </ul>

                </DialogContentText>
              )
            })}
          </DialogContent>
          <DialogActions>

            <Button onClick={() => setIsOpenRemove(false)} autoFocus>
              Cancel
            </Button>
            {brandProducts.length < 1 && (
              <Button variant="contained" sx={{
                m: 1,
                color: "#fff !important",
                backgroundColor: "red",
                width: 30,
                "&:hover": {
                  backgroundColor: "red !important", color: '#fff !important'// Set the background color on hover
                },
              }} onClick={removeBrand}><DeleteIcon /></Button>
            )}

          </DialogActions>
        </Dialog>
        <AddBrandModal open={isAddModalOpen} onClose={closeAddModal} />
        <EditModal open={isEditModalOpen} onClose={closeEditModal} storeRow={isEditRow} />
      </div>) : (
        <CircularProgress />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
import React from 'react';
import './signUp.scss';
import logo from '../../assets/logo/logo_tagline.png';
const LoginPage: React.FC = () => {
  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <h2>Welcome!</h2>
      <form className="login-form">
        <div className="form-group">
          <input type="email" placeholder="Email" className="input-field" />
        </div>
        <div className="form-group">
          <input type="password" placeholder="Password" className="input-field" />
        </div>
        <div className="form-group">
          <button type="submit" className="login-button">Login</button>
        </div>
        <div className="form-group">
          <a href="#" className="forget-password">Forget password?</a>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;

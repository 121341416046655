import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, MenuItem, Select, SelectChangeEvent, InputLabel } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./symptoms.scss";
import { OrganAPI, SymptomsAPI } from '../../api/services';

interface TextModalProps {
    open: boolean;
    onClose: () => void;
}

interface MyOrganProps { label: string; value: number }

const TextModal: React.FC<TextModalProps> = ({ open, onClose }) => {
    const [name, setName] = useState<string>('');
    const [isAddLoading, setisAddLoading] = useState(false);
    const [selectedOrgan, setSelectedOrgan] = React.useState(0);
    const [optionsOrgan, setOptionsOrgan] = React.useState<MyOrganProps[]>();

    const handleOrganChange = (event: SelectChangeEvent<number>) => {
        setSelectedOrgan(event.target.value as number);
        console.log(event.target.value)
    };

    const handleSave = (name: string, selectedOrgan: number) => {
        if (name === '') {
            toast.error("Name can't be empty!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setisAddLoading(false);
        } else {
            const loggedInId = JSON.parse(String(localStorage.getItem('formData')))["id"];
            let data = {
                "name": name,
                "targated_organ": selectedOrgan,
                "created_by": Number(loggedInId),
                "updated_by": Number(loggedInId)
            }
            SymptomsAPI.addSymptoms(data).then((res) => {
                
                    setName('')
                    setisAddLoading(false);
                    toast.success("Symptoms Added Successfully!", {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    onClose()
                
            }).catch((error)=>{
                toast.error("Symptoms with targated organ already exist!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setisAddLoading(false);
            })
        }

    };

    React.useEffect(() => {
        const templist: { label: string; value: number }[] = new Array();

        const templist2: { label: string; value: number }[] = new Array();
        OrganAPI.getAllOrgan().then(async (response) => {
            response["data"]
                .sort((a: { name: string }, b: { name: string }) =>
                    a.name.localeCompare(b.name)
                )
                .forEach((element: { name: string; id: number; is_deleted: boolean }) => {
                    if (!element.is_deleted) {
                        templist2.push({ label: element.name, value: element.id });
                    }
                });
            setOptionsOrgan(templist2)
        });
    }, [])


    return (
        <Dialog open={open} onClose={onClose} className="text-modal-dialog" style={{ borderRadius: 20 }}>
            <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>Add Symptoms</DialogTitle>
            <DialogContent>
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    InputProps={{
                        disableUnderline: true, // <== added this
                    }}
                    placeholder="Name"
                    className='addText'
                    onChange={(e) => setName(e.currentTarget.value)}
                    value={name}
                />
                <InputLabel style={{ marginTop: 10 }}>Select an Organ</InputLabel>
                <Select
                    value={selectedOrgan}
                    onChange={handleOrganChange}
                    displayEmpty={true}
                    inputProps={{ 'aria-label': 'Select an option' }}
                    className='drop-down'
                >
                    {optionsOrgan?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {isAddLoading ?
                    (
                        <Button><CircularProgress size={20} style={{ color: '#70a132' }} /></Button>
                    ) : (
                        <Button onClick={() => {
                            setisAddLoading(true);
                            handleSave(name, selectedOrgan)
                        }}>Save</Button>
                    )}
            </DialogActions>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Dialog>

    );
};

export default TextModal;

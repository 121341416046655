import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./notPermittedBrand.scss";
import { NotPermittedBrandAPI, StoreAPI, UsersAPI } from '../../api/services';

interface TextModalProps {
    open: boolean;
    onClose: () => void;
}

const AddFileModal: React.FC<TextModalProps> = ({ open, onClose }) => {
    const [name, setName] = useState<string>('');
    const [isAddLoading, setisAddLoading] = useState(false);
    const [selectedFile, setSelectedFile] = React.useState<any>(null);

    const handleSave = () => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        UsersAPI.addFiles(formData).then(() => {
            onClose();
        })
    };

    function handleFileChange(event: any) {
        setSelectedFile(event.target.files[0]);
      }

    return (
        <Dialog open={open} onClose={onClose} className="text-modal-dialog" style={{ borderRadius: 20 }}>
            <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>Add File</DialogTitle>
            <DialogContent>
            <input style={{marginTop: 40}} type="file" onChange={(e) => {
                handleFileChange(e)
            }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={() => {
                            handleSave()
                        }}>Save</Button>
            </DialogActions>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Dialog>

    );
};

export default AddFileModal;

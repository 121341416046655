import * as React from "react";
import SideNavigation from "../../navigation/SideNavigation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./home.scss";


export default function HomeScreen() {
  const navigate = useNavigate();
  return (
    <>
        <div id="home-container">
          <Helmet>
            <title>{'AlleGrow-Home'}</title>
          </Helmet>
          <div>
            <SideNavigation />
          </div>
          <div className="child">
          {/* <iframe className="iframe" title="Report Section" style={{marginTop: 70}} src="https://app.powerbi.com/view?r=eyJrIjoiOWVmNWRkYzYtYmNkMC00OTYyLWE2NTUtNDI4YmE3N2QwNDE5IiwidCI6IjY2ZWE5ZjM2LTMxMTctNDFjNi04MWQxLTYyYjk4Njg1ZWY2ZSJ9" allowFullScreen={true}></iframe> */}
          <iframe className="iframe" title="Report Section" style={{marginTop: 70}} src="https://app.powerbi.com/view?r=eyJrIjoiOWVmNWRkYzYtYmNkMC00OTYyLWE2NTUtNDI4YmE3N2QwNDE5IiwidCI6IjY2ZWE5ZjM2LTMxMTctNDFjNi04MWQxLTYyYjk4Njg1ZWY2ZSJ9" allowFullScreen={true}></iframe>
          </div>
        </div>
    </>
  );
}
import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import "./category.scss";
import { CategoryAPI } from '../../api/services';
import { category } from '../../types/category';

interface TextModalProps {
    open: boolean;
    onClose: () => void;
    categoryRow: category;
}

const EditModal: React.FC<TextModalProps> = ({ open, onClose, categoryRow }) => {
    const [name, setName] = useState<string>('');
    const [checked, setChecked] = useState<any>(false);
    const [isAddLoading, setisAddLoading] = useState(false);
    const [file, setFile] = React.useState(null);
    const [image, setImage] = React.useState<string | undefined>('')

    const handleChange = (event: any) => {
        setChecked(event.target.checked);
    };

    const handleSave = async (name: string) => {
        await setisAddLoading(true);
        if (name === '') {
            toast.error("Name can't be empty!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setisAddLoading(false);
        } else {
            const loggedInId = JSON.parse(String(localStorage.getItem('formData')))["id"];
            // let data = {
            //     "name": name,
            //     "is_deleted": checked,
            //     "updated_by": Number(loggedInId)
            // }
            let formData = new FormData()
            formData.append('name', name)
            formData.append('is_deleted', checked)
            formData.append('updated_by', Number(loggedInId).toString())
            if(file !== null){
                formData.append('icon', file)
            }
            CategoryAPI.updateCategory(formData, categoryRow.id).then((res) => {
                setisAddLoading(false);
                toast.success("Successfully Updated!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                onClose()
            }).catch(() => {
                toast.error("Unable to update or Category with same name already exist!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setisAddLoading(false);
            })

        }

    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
        const imageURL = URL.createObjectURL(file);
        setImage(imageURL);
      };

    React.useEffect(() => {
        setName(categoryRow.name);
        setChecked(categoryRow.is_deleted)
        if(categoryRow.icon !== ''){
            setImage(categoryRow.icon)
        }
    }, [open])
    return (
        <Dialog open={open} onClose={onClose} className="text-modal-dialog" style={{ borderRadius: 20 }} >
            <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>Edit Food Category</DialogTitle>
            <DialogContent>
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    InputProps={{
                        disableUnderline: true, // <== added this
                    }}
                    placeholder="Name"
                    className='addText'
                    onChange={(e) => setName(e.currentTarget.value)}
                    value={name}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            className='checkBox'
                            color='success'
                        />
                    }
                    label="Is InActive"
                    className='checkBox'
                />
                <input style={{width: '100%'}} type="file" onChange={handleFileChange} />
                {image !== '' && (
                    <img style={{marginTop: '20px',width: '100px', height: '100x'}} src={image}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {isAddLoading ?
                    (
                        <Button><CircularProgress size={20} style={{ color: '#70a132' }} /></Button>
                    ) : (
                        <Button onClick={() => {
                            setisAddLoading(true);
                            handleSave(name)
                        }}>Save</Button>
                    )}
            </DialogActions>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Dialog>

    );
};

export default EditModal;

import * as React from "react";
import { Button, CircularProgress } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoneIcon from '@mui/icons-material/Done';
import Close from "@mui/icons-material/Close";
import {
  DataGrid,
} from "@mui/x-data-grid";
import SideNavigation from "../../navigation/SideNavigation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import "./organ.scss";
import { organ } from "../../types/organ";
import {OrganAPI } from "../../api/services";
import TextModal from "./addOrgan";
import EditModal from "./editOrgan";


export default function OrganScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLogged, setIsLogged] = React.useState(false)
  const [isAddModalOpen, setAddModalOpen] = React.useState(false);
  const [isEditModalOpen, setEditModalOpen] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState(false);
  const [isOpenActivate, setIsOpenActivate] = React.useState(false);
  const [isDeleted, setIsDeleted] = React.useState({
    id: 0,
    is_deleted: false
  });
  const [isEditRow, setIsEditRow] = React.useState<organ>({
    id: 0,
    name: '',
    is_deleted: false,
    created_by: 0,
    updated_by: 0,
    created_date: new Date,
    modified_date: new Date,
  })

  const [OrganData, setOrganData] = React.useState<organ[]>([])


  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  React.useEffect(() => {
    if (!localStorage.getItem('formData')) {
      navigate('/Login')
    } else {
      setIsLogged(true)
      OrganAPI.getAllOrgan().then((res) => {
        setOrganData(res.data);
        console.log(res.data)
        setIsLoading(false)
      })
    }


  }, [isAddModalOpen, isOpenDelete, isEditModalOpen, isOpenActivate])

  const deleteOrgan = (isDeleted: any) => {
    const deletdId = isDeleted.id;
    const data = { is_deleted: isDeleted.is_deleted }
    console.warn(deletdId, data)
    OrganAPI.updateOrgan(data, deletdId).then((res) => {
      console.log(res)
      setIsOpenDelete(false)
      setIsOpenActivate(false)
    })
  }

  const columns: any = [
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params: any) => {
        const onViewClick = (e: any) => {
          setIsEditRow(params.row);
          setEditModalOpen(true);
        };

        return (
          <>
            <Button
              variant="contained"
              sx={{ m: 0, color: "#fff", backgroundColor: "#70a132", width: 30 }}
              onClick={onViewClick}
            >
              <ModeEditIcon style={{ fontSize: 23 }} />
            </Button>

          </>
        );
      },
    },
    { field: "name", headerName: "Name", width: 300 },
    {
      field: "is_delete", headerName: "Is Active", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            {
              params.row.is_deleted ?
                (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                      setIsDeleted({ id: params.row.id, is_deleted: false });
                      if(!params.row.is_deleted){
                      setIsOpenDelete(true);
                      }else{
                        setIsOpenActivate(true);
                      }
                    }}
                  >
                    <Close style={{ fontSize: 23 }} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                      setIsDeleted({ id: params.row.id, is_deleted: true });
                      setIsOpenDelete(true);
                    }}
                  >
                    <DoneIcon style={{ fontSize: 23 }} />
                  </Button>
                )}
          </>);
      }
    },

  ];
  return (
    <>
      {isLogged ? (<div id="home-container">
        <Helmet>
          <title>{'AlleGrow-Organ'}</title>
        </Helmet>
        <div>
          <SideNavigation />
        </div>
        <div className="child">
          <div className="header">
            <p style={{ fontSize: 25, fontWeight: 500, color: "#70a132", marginTop: 80  }}>Organs</p></div>
          <div className="tableheader">

            <Button
              variant="contained"
              sx={{ color: "#70a132", backgroundColor: "#fff", width: 25, fontSize: 15, height: 30 }}
              onClick={openAddModal}
            >Add
            </Button>
          </div>
          {isLoading ?
            (
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <CircularProgress size={50} style={{ marginTop: 50, color: '#70a132' }} />
              </div>
            ) : (<div style={{ width: "100%" }}>
              <DataGrid
                style={{ maxHeight: "100%", paddingLeft: 15, paddingRight: 15 }}
                rows={OrganData}
                columns={columns}
                getRowId={row => row.id}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                rowSelection={false}
              />
            </div>)}
        </div>
        <Dialog
          open={isOpenDelete}
          onClose={() => setIsOpenDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Deactivate Organ"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to deactivate this organ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteOrgan(isDeleted)}>Yes</Button>
            <Button onClick={() => setIsOpenDelete(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isOpenActivate}
          onClose={() => setIsOpenActivate(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Activate Organ"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to activate this organ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteOrgan(isDeleted)}>Yes</Button>
            <Button onClick={() => setIsOpenActivate(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <TextModal open={isAddModalOpen} onClose={closeAddModal} />
        <EditModal open={isEditModalOpen} onClose={closeEditModal} organRow={isEditRow} />
      </div>) : (
        <CircularProgress />
      )}
    </>
  );
}
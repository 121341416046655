import * as React from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoneIcon from '@mui/icons-material/Done';
import Close from "@mui/icons-material/Close";
import {
  DataGrid,
} from "@mui/x-data-grid";
import SideNavigation from "../../navigation/SideNavigation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import "./notPermittedBrand.scss";
import { store } from "../../types/store";
import { NotPermittedBrandAPI, ProductAPI, StoreAPI, UsersAPI } from "../../api/services";
import AddBrandModal from "./addFIleModal";
import EditModal from "./editNotPermittedBrand";
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { file } from "../../types/file";
import AddFileModal from "./addFIleModal";


export default function AboutFiles() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLogged, setIsLogged] = React.useState(false)
  const [isAddModalOpen, setAddModalOpen] = React.useState(false);
  const [isEditModalOpen, setEditModalOpen] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState(false);
  const [isOpenActivate, setIsOpenActivate] = React.useState(false);
  const [brandProducts, setBrandProducts] = React.useState<any>([])
  const [isOpenRemove, setIsOpenRemove] = React.useState(false);
  const [selectedBrand, setSelectedBrand] = React.useState<any>({})
  const [specialKey, setSpecialKey] = React.useState('')
  const [files, setFiles] = React.useState<store[]>([]);
  const [isDeleted, setIsDeleted] = React.useState({
    id: 0,
    is_deleted: false
  });
  const [isEditRow, setIsEditRow] = React.useState<store>({
    id: 0,
    name: '',
    is_deleted: false,
    created_by: 0,
    updated_by: 0,
    created_date: new Date,
    modified_date: new Date,
  })

  const [BrandData, setBrandData] = React.useState<store[]>([])


  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  React.useEffect(() => {
      UsersAPI.getFiles().then((res) => {
        setFiles(res.data);
      })
  }, [isAddModalOpen])

  const deleteStore = (isDeleted: any) => {
    const deletdId = isDeleted.id;
    const data = { is_deleted: isDeleted.is_deleted }
    NotPermittedBrandAPI.updateBrand(data, deletdId).then((res) => {
      setIsOpenDelete(false)
      setIsOpenActivate(false)
    })
  }

  const getFiles = () => {
    UsersAPI.getFiles().then((res) => {
      setFiles(res.data);
    })

  }

  React.useEffect(() => {
    getFiles();
  }, [selectedBrand])

  const removeBrand = (id : any) => { 
    NotPermittedBrandAPI.deleteBrand(id).then((res) => {
      NotPermittedBrandAPI.getAllBrands().then((res) => {
        setBrandData(res.data);
        setIsLoading(false)
      })
      toast.success("Brand Deleted", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    })
  }

  const columns: any = [
//     const url = "https://allegrow.blob.core.windows.net/media/export.pdf";
// const filename = url.split("/media/")[1];
    { field: "file", headerName: "File", width: 600,  renderCell: (params: any) => {
      return (
        <>
        {params.row.file.split('/media/')[1]}
        </>
      );
    }},
    {
      field: "down", headerName: "Download", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              variant="contained"
              sx={{
                m: 1,
                color: "#fff",
                backgroundColor: "#70a132",
                width: 30,
                "&:hover": {
                  backgroundColor: "#70a132 !important", // Set the background color on hover
                },
              }}
              onClick={() => {
                let pdfUrl = params.row.file;
                const link = document.createElement("a");
                link.href = pdfUrl;
                link.target = '_blank';
                link.download = params.row.file.split('/media/')[1]; // specify the filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              <CloudDownloadIcon style={{ fontSize: 23 }} />
            </Button>
          </>
        );
      }
    },
    {
      field: "del", headerName: "Delete", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              variant="contained"
              sx={{
                m: 1,
                color: "#fff",
                backgroundColor: "red",
                width: 30,
                "&:hover": {
                  backgroundColor: "red !important", // Set the background color on hover
                },
              }}
              onClick={() => {
                let id = params.row.id;
                UsersAPI.deleteFile(id).then(() => {
                  UsersAPI.getFiles().then((res) => {
                    setFiles(res.data)
                  })
                })
              }}
            >
              <DeleteIcon style={{ fontSize: 23 }} />
            </Button>
          </>
        );
      }
    },
  ];
  return (
    <>
      <div id="home-container">
        <Helmet>
          <title>{'AlleGrow-About(Files)'}</title>
        </Helmet>
        <div>
          <SideNavigation />
        </div>
        <div className="child">
          <div className="header">
            <p style={{ fontSize: 25, fontWeight: 500, color: "#70a132", marginTop: 80 }}>About (Files)</p></div>
          <div className="tableheader">

            <Button
              variant="contained"
              sx={{ color: "#70a132", backgroundColor: "#fff", width: 25, fontSize: 15, height: 30 }}
              onClick={openAddModal}
            >Add
            </Button>
          </div><div style={{ width: "100%" }}>
              <DataGrid
                style={{ maxHeight: "100%", paddingLeft: 15, paddingRight: 15 }}
                rows={files}
                columns={columns}
                getRowId={row => row.id}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                rowSelection={false}
              />
            </div>
        </div>
        <AddFileModal open={isAddModalOpen} onClose={closeAddModal} />
        {/* <EditModal open={isEditModalOpen} onClose={closeEditModal} storeRow={isEditRow} /> */}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
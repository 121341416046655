import React,{Fragment, useState} from 'react'
import {FiMenu,FiX,} from 'react-icons/fi'
import './navbar.scss'
import { useNavigate } from 'react-router-dom';

interface NavBarProps {
    navbarLinks: {
      title: string;
      url: string;
    }[];
  }

export default  function Navbar({navbarLinks}:NavBarProps) {

  
    const [menuClicked,setMenuClicked] = useState(false);

    function toggleMenuClick(){
        setMenuClicked(!menuClicked);
    }

    const navigate = useNavigate()
  return (
    <Fragment>
      <nav className="navbar">
        {!menuClicked ? (
          <FiMenu
            size={25}
            className="navbar__menu"
            onClick={toggleMenuClick}
          />
        ) : (
          <FiX size={25} className="navbar__menu" onClick={toggleMenuClick} />
        )}
        <ul
          className={
            !menuClicked ? "navbar__list" : "navbar__list navbar__list--active"
          }
        >
          {navbarLinks.map((item: any) => {
            return (
              <li className="navbar__item" key={item.title}>
                <a
                  className="navbar__link"
                  // href={item.url}
                  onClick={() => {
                    if(item.title === 'Allergy Information'){
                      window.open('https://www.allergyfoundation.co.za/allergy-information/allergic-diseases/food-allergy/', '_blank')
                    }else{
                      setMenuClicked(!menuClicked)
                    navigate(item.url)
                    }
                  }}
                >
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </Fragment>
  );
}

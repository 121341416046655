import React, { Fragment } from "react";
import "./home.scss";
import logo from "../../assets/logo/FoodAllergySupportApp.png";
import A from "../../assets/images/A - allegrow logo.png";
import E from "../../assets/images/E - allegrow logo.png";
import download from "../../assets/images/download.png";
import Card from "./card";
import { Box } from "@mui/material";

const Home = () => {
  return (
    <Box sx={{ pt: '0px', height: 'calc(100vh - 150px)', marginBottom: 10, flex: 1 }} className='homeContainer'>
      {/* <div className="homeContainer"> */}
      <Box
        className='top'
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          pt: 5
        }}
      >
        <Box
          className='top-left'
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2 style={{ fontFamily: "margarosa", marginBottom: 0, fontSize: '2em' }}>Welcome to Allegrow</h2>
          <p style={{ fontSize: "26px" }}>
            <i>(Joyfully growing with allergies)</i>
          </p>

          <p className='welcome-text' style={{ width: '50%', fontSize: '20px', textAlign: 'justify' }}>
            The Allegrow App strives to simplify label
            reading, save time, and guide you to suitable
            food products. Track your food allergy & intolerance
            journey and enjoy a less complicated life!
          </p>
        </Box>

        <Box
          className='top-right'
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img className="homeImage" src={logo} alt="Image" />
        </Box>
      </Box>
      <Box
        className='middle'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-around',
          pr: 10,
          pl: 10,
          pt: 5,
          paddingBottom: '150px'
        }}>
        <Card
          img={A}
          title="About allegrow"
          btnColour="#ffc30c"
          btnText="READ MORE"
          navUrl="/About"
        />
        <Card
          img={download}
          title="Download App"
          btnColour="#ea947a"
          btnText="DOWNLOAD"
          navUrl="/download"
        />
        <Card
          img={E}
          title="Explore AlleGrow & Where To Start"
          btnColour="#70a132"
          btnText="EXPLORE"
          navUrl="/explore"
        />
      </Box>
      {/* </div> */}
    </Box>
  );
};

export default Home;

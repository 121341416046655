import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Checkbox, Chip, CircularProgress, FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DoneIcon from "@mui/icons-material/Done";
import Close from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import SideNavigation from "../../navigation/SideNavigation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import "./bulkUpload.scss";
import { store } from "../../types/store";
import { AllergyAPI, BrandAPI, CategoryAPI, IngredientAPI, ProductAPI, StoreAPI, SubCategoryAPI, SyncIngredients } from "../../api/services";
import { ToastContainer, toast } from "react-toastify";
import { Box } from "@mui/system";
import * as XLSX from "xlsx";
import AddStoreModal from '../store/addStore';
import AddBrandModal from "../brand/addBrand";
import AddCategoryModal from "../category/addCategory";
import AddSubCategoryModal from "../subCategory/addSubCategory";
import AddIngredientModal from "../ingredients/addIngredient";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";

export default function BulkUploadScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [products, setProducts] = React.useState<any>([]);
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const [allIngredients, setAllIngredients] = React.useState<any[]>([]);
  const [allStores, setAllStores] = React.useState<any[]>([]);
  const [allCategories, setAllCategories] = React.useState<any[]>([]);
  const [allSubCategories, setAllSubCategories] = React.useState<any[]>([]);
  const [allBrands, setAllBrands] = React.useState<any[]>([]);
  const [storesNotExist, setStoresNotExist] = React.useState<any[]>([]);
  const [brandsNotExist, setBrandsNotExist] = React.useState<any[]>([]);
  const [categoriesNotExist, setCategoriesNotExist] = React.useState<any[]>([]);
  const [subcategoriesNotExist, setSubCategoriesNotExist] = React.useState<any[]>([]);
  const [ingredientsNotExist, setIngredientsNotExist] = React.useState<any[]>([]);
  const [addStoreModal, setAddStoreModal] = React.useState(false);
  const [addBrandModal, setAddBrandModal] = React.useState(false);
  const [addCategoryModal, setAddCategoryModal] = React.useState(false);
  const [addSubCategoryModal, setAddSubCategoryModal] = React.useState(false);
  const [addIngredientModal, setAddIngredientModal] = React.useState(false);
  const [mayContains, setMayContains] = React.useState([]);

  console.log(allSubCategories)

  function handleFileChange(event: any) {
    setSelectedFile(event.target.files[0]);
  }

  const openIngredientAddModal = () => {
    setAddIngredientModal(true);
  };

  const closeIngredientAddModal = async () => {
    setAddIngredientModal(false);
    getAllIngredients()
    fetchProductPreviewData();
  };

  const openAddSubCategoryModal = () => {
    setAddSubCategoryModal(true);
  };

  const getIngredientName = (value: any) => {
    let ingredient = allIngredients.filter((ing) => ing.id === Number(value))[0];
    return ingredient.name;
  };

  const closeAddSubCategoryModal = async () => {
    setAddSubCategoryModal(false);
    getAllSubCategories()
    fetchProductPreviewData();
  };

  const openAddStoreModal = () => {
    setAddStoreModal(true);
  };

  const closeAddStoreModal = async () => {
    setAddStoreModal(false);
    getAllStores();
    fetchProductPreviewData();
  };

  const openAddBrandModal = () => {
    setAddBrandModal(true);
  };

  const closeAddBrandModal = () => {
    setAddBrandModal(false);
    getAllBrands();
    fetchProductPreviewData();
  };

  const openAddCategoryModal = () => {
    setAddCategoryModal(true);
  };

  const closeAddCategoryModal = async () => {
    setAddCategoryModal(false);
    getAllCategories();
    fetchProductPreviewData();
  };

  const syncIngredients = async (ingredients: any[]) => {
    // console.log(ingredients)

    const formattedIngredientData = ingredients
      .slice(0)
      .map((row: any, index) => ({
        name: row.Name,
        contains: row.Contains,
      }));

    //apis post
    const result = await SyncIngredients.syncIng(formattedIngredientData);
    // console.log(res);
    setAllIngredients(result.data);
    return result.data;
  };

  const fetchProductPreviewData = async () => {
    setStoresNotExist([]);
    setProducts([]);
    if (selectedFile) {
      setIsLoading(true);
      setAddModalOpen(false);
      console.log(allSubCategories)
      await new Promise((resolve) => setTimeout(resolve, 3000));

      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileContent = event.target?.result; // Use optional chaining to access result
        if (fileContent) {
          //products sheet
          const workbook = XLSX.read(fileContent, { type: "binary" });
          const sheetName = workbook.SheetNames[1]; // Assuming the data is in the second sheet
          const sheet = workbook.Sheets[sheetName];
          const parsedData: any[] = XLSX.utils.sheet_to_json(sheet);

          //ingredients sheet
          const ingredientSheet = workbook.SheetNames[0]; // Assuming the data is in the second sheet
          const ingsheet = workbook.Sheets[ingredientSheet];
          const parsedingData: any[] = XLSX.utils.sheet_to_json(ingsheet);

          const temp_ing = await syncIngredients(parsedingData);
            const formattedProductData = parsedData
              .slice(0)
              .map((row: any, index) => ({
                name: row.Name,
                description: row.Description,
                brand_id: allBrands.find(
                  (x: any) => x.name.toLowerCase() === row.Brand.toLowerCase()
                )?.id,
                category_id: allCategories.find(
                  (x: any) =>
                    x.name.toLowerCase() === row["Food Category"].toLowerCase()
                )?.id,
                subcategory_id: allSubCategories.filter(
                  (x: any) =>
                    x.name.toLowerCase() === row["Food SubCategory"].toLowerCase()
                )[0]?.id,
                stores: getProductStores(row.Stores),
                mayContain: getMayContains(row["May Contain"]),
                ingredients: getProductIngredients(
                  row.Name,
                  parsedData.slice(0),
                  temp_ing
                ),
                image: null,
                imageUrl: "",
                imageName: row.Image
              }));

            let productData: any = [];
            for (var item of formattedProductData) {
              if (
                productData.filter((p: any) => p.name === item.name).length < 1
              ) {
                productData.push(item);
              }
            }
            getNonExistBrands(parsedData.slice(0));
            getNonExistCategories(parsedData.slice(0));
            getNonExistSubCategories(parsedData.slice(0));
            setProducts(productData);
            setIsLoading(false);
        }
      };
      reader.readAsBinaryString(selectedFile);
    }
  };

  const getMayContainName = (value: any) => {
    let contains = mayContains.filter((m: string) => m === value);
    return contains;
  };

  const handleMayContainIngredientChange = (
    event: SelectChangeEvent<any>, index: any
  ) => {
    const {
      target: { value },
    } = event;
    let p = [...products]
    p[index].mayContain = typeof value === "string" ? value.split(",") : value
    setProducts(p)
  };

  const getMayContains = (values: any) => {
    var trimmedArray = [];
    if (values) {
      const splitArray = values.split(",");
      trimmedArray = splitArray.map((item: any) => item.trim());
    }
    return trimmedArray
  }

  const getProductStores = (values: any) => {
    let productStoreIds: any[] = [];
    var trimmedArray = [];
    if (values) {
      const splitArray = values.split(",");
      trimmedArray = splitArray.map((item: any) => item.trim());
    }
    let productStores: any[] = trimmedArray.filter((name: string) =>
      allStores.some((store) => store.name === name)
    );
    let storesToAdd: any[] = trimmedArray.filter(
      (name: string) => !allStores.some((store) => store.name.toLowerCase() === name.toLowerCase())
    );
    for (var store of productStores) {
      let item = allStores.find((x) => x.name.toLowerCase() === store.toLowerCase());
      productStoreIds.push(item.id);
    }

    // console.log(productStoreIds)
    setStoresNotExist((storesNotExist) => [...storesNotExist, ...storesToAdd])
    return productStoreIds;
  };

  const getProductIngredients = (name: string, values: any, temp_ing: any) => {
    let productIngredientIds: any[] = [];
    let ingredientsToAddNames: any[] = ingredientsNotExist;
    let productIngredients: any[] = values.filter((item: any) => item.Name === name);
    let ingredientsToAdd: any[] = productIngredients.filter(
      (item: any) => !temp_ing.some((ing: any) => ing.name.toLowerCase() === item.Ingredient.toLowerCase())
    );
    let ingredientsToSet: any[] = productIngredients.filter(
      (item: any) => temp_ing.some((ing: any) => ing.name.toLowerCase() === item.Ingredient.toLowerCase())
    );

    for (var item of ingredientsToSet) {
      productIngredientIds.push(temp_ing.find((x: any) => x.name.toLowerCase() === item.Ingredient.toLowerCase())?.id);
    }
    for (var item of ingredientsToAdd) {
      if (ingredientsToAddNames.indexOf(item.Ingredient) < 0) {
        ingredientsToAddNames.push(item.Ingredient);
      }
    }

    // console.log(ingredientsToAddNames);


    setIngredientsNotExist(ingredientsToAddNames)
    return productIngredientIds;
  }

  const getNonExistBrands = (data: any[]) => {
    let brandsToAdd: any = [];
    for (var product of data) {
      // if(!allBrands.find((brand:any) => brand.name.toLowerCase() === product.Brand.toLowerCase())){
      //   brandsToAdd.push(product.Brand)
      // }
      if (!allBrands.find((brand: any) => brand.name.toLowerCase() === product.Brand.toLowerCase()) && brandsToAdd.indexOf(product['Brand']) < 0) {
        brandsToAdd.push(product.Brand)
      }
    }
    setBrandsNotExist(brandsToAdd);
    // console.log(brandsToAdd)
  }

  const getNonExistCategories = (data: any[]) => {
    let catToAdd: any[] = [];
    for (var product of data) {
      if (!allCategories.find((cat: any) => cat.name.toLowerCase() === product["Food Category"].toLowerCase()) && catToAdd.indexOf(product["Food Category"]) < 0) {
        catToAdd.push(product['Food Category'])
      }
    }
    setCategoriesNotExist(catToAdd);
    // console.log(catToAdd)
  }

  const getNonExistSubCategories = (data: any[]) => {
    console.log(allSubCategories)
    let subCategoriesToAdd = [];
    for (var product of data) {
      if (allSubCategories.filter((cat: any) => cat.name.toLowerCase() === product["Food SubCategory"].toLowerCase()).length < 1 && subCategoriesToAdd.indexOf(product["Food SubCategory"]) < 0) {
        subCategoriesToAdd.push(product["Food SubCategory"])
      }
    }
    setSubCategoriesNotExist(subCategoriesToAdd);
  }



  const getAllBrands = () => {
    BrandAPI.getAllBrands().then((res) => {
      let brandNotExist_data = brandsNotExist;
      setBrandsNotExist(brandNotExist_data.filter((name) => !res.data.some((brand: any) => brand.name === name)))
      setAllBrands(res.data);
    })
  };

  const getAllIngredients = async () => {
    IngredientAPI.getAllIngredients().then((res) => {
      let ingredientsNotExist_data = ingredientsNotExist;
      setIngredientsNotExist(ingredientsNotExist_data.filter((name) => !res.data.some((ing: any) => ing.name.toLowerCase() === name.toLowerCase())))
      setAllIngredients(res.data);
      return true;
    })
  };

  const getAllStores = async () => {
    StoreAPI.getAllStore().then((res) => {
      let storeNotExist_data = storesNotExist;
      setStoresNotExist(storeNotExist_data.filter((name) => !res.data.some((store: any) => store.name === name)))
      setAllStores(res.data);

    })
  };

  const getAllCategories = () => {
    CategoryAPI.getAllCategory().then((res) => {
      let catNotExist_data = categoriesNotExist;
      setCategoriesNotExist(catNotExist_data.filter((name) => !res.data.some((store: any) => store.name === name)))
      setAllCategories(res.data);
    })
  };

  const getAllSubCategories = async () => {
    SubCategoryAPI.getAllSubCategory().then((res) => {
      let catNotExist_data = subcategoriesNotExist;
      setSubCategoriesNotExist(catNotExist_data.filter((name) => !res.data.some((subcat: any) => subcat.name.toLowerCase() === name.toLowerCase())))
      setAllSubCategories(res.data);
      setIsLoading(false)
    })

  };

  const getMayContain = () => {
    AllergyAPI.getAllergyContains().then((res) => {
      setMayContains(res.data);
      setIsLoading(false);
    })
  }

  React.useEffect(() => {
    setIsLoading(true);
    getAllBrands();
    getAllIngredients();
    getAllStores();
    getAllCategories();
    getAllSubCategories();
    getMayContain();
  }, [])


  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#70a132",
        dark: "#70a132",
      },
    },
  });

  const getStoreName = (value: any) => {
    let store = allStores.filter((store) => store.id === Number(value))[0];
    return store.name;
  };

  const handleStoreChange = (event: any, index: any) => {
    let value = event.target.value;
    let p = [...products];
    p[index]['stores'] = typeof value === "string" ? value.split(",") : value;
    console.log(p[0])
    setProducts(p);
  };

  const handleIngredientsChange = (idsList: number[], index: number) => {
    let p = [...products];
    p[index].ingredients = idsList;
    setProducts(p)
  }

  const submit = async () => {
    setIsLoading(true);
    for (var item of products) {
      toast.info(`Processing ${item.name}`);
      const formData = new FormData();
      formData.append("name", item.name);
      if (item.image instanceof File) {
        formData.append("image", item.image);
      }
      formData.append("brand_id", item.brand_id.toString());
      formData.append("category_id", item.category_id.toString());
      formData.append("subcategory_id", item.subcategory_id.toString());
      if (item.description !== undefined && item.description !== 'undefined') {
        formData.append("description", item.description);
      }

      await new Promise((resolve) => setTimeout(resolve, 1500));
      var result = await sendData(formData, item);
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }
    toast.success("Products Uploaded");
    await new Promise((resolve) => setTimeout(resolve, 1500));
    navigate("/Products");
    setIsLoading(false)
  }

  const sendData = async (formData: any, productDetails: any) => {
    ProductAPI.addProduct(formData)
      .then((res) => {
        let productId = res.data.id;
        let stores = [];
        let ingredients: any[] = [];
        let mayContain: any[] = []
        for (var item of productDetails.stores) {
          stores.push({
            productId: productId,
            storeId: item,
          });
        }
        for (var item of productDetails.ingredients) {
          ingredients.push({
            productId: productId,
            ingredientId: item,
          });
        }
        console.log("PRODUCTDETAILS", productDetails)
        for (var item of productDetails.mayContain) {
          console.log("Item", item)
          mayContain.push({
            productId: productId,
            maycontain: item,
          });
        }
        console.log("MayContain", mayContain)
        ProductAPI.addProductStores(stores).then(() => {
          ProductAPI.addProductIngredients(ingredients).then(() => {
            ProductAPI.addProductMayContainIngredients(mayContain).then(() => {
              return true;
            })

          });
        });
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Product already exists!", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };




  //onDragEnd
  const onDragEnd = (result: DropResult, idx: number) => {
    console.log(result);
    console.log(idx)
    // {console.log(productDetails.ingredients.length)}
    const { source, destination, type } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    if (type === "group") {
      const reorderIngredient = [...products[idx].ingredients];
      // console.log(reorderIngredient);

      // {console.log(products.map((x: any) => (x.ingredients.map((ingredientId: any) =>
      //   getIngredientName(ingredientId))) ))}

      console.log(products[idx].ingredients);


      const sourceIndex = source.index;
      // console.log(sourceIndex);

      const destIndex = destination.index;
      // console.log(destIndex);

      console.log(reorderIngredient);

      const [removedIngredient] = reorderIngredient.splice(sourceIndex, 1);

      reorderIngredient.splice(destIndex, 0, removedIngredient);

      console.log(reorderIngredient);

      handleIngredientsChange(reorderIngredient, idx)


    }

  }

  console.log(products)


  return (
    <>
      <div id="home-container">
        <Helmet>
          <title>{"AlleGrow-BulkUpload"}</title>
        </Helmet>
        <div>
          <SideNavigation />
        </div>
        <div className="child">
          <div className="header">
            <p
              style={{
                fontSize: 25,
                fontWeight: 500,
                color: "#70a132",
                marginTop: 80,
              }}
            >
              Bulk Uploads
            </p>
          </div>
          <div className="tableheader">
            <Button
              variant="contained"
              sx={{
                color: "#70a132",
                backgroundColor: "#fff",
                width: 100,
                fontSize: 15,
                height: 30,
                marginRight: 2
              }}
              onClick={() => setAddModalOpen(true)}
            >
              Add File
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#70a132",
                backgroundColor: "#fff",
                width: 170,
                fontSize: 15,
                height: 30,
                marginRight: 2
              }}
              onClick={() => window.location.reload()}
            >
              Cancel Upload
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#70a132",
                backgroundColor: "#fff",
                width: 120,
                fontSize: 15,
                height: 30,
                marginRight: 2
              }}
              onClick={openAddStoreModal}
            >
              Add Store
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#70a132",
                backgroundColor: "#fff",
                width: 125,
                fontSize: 15,
                height: 30,
                marginRight: 2
              }}
              onClick={openAddBrandModal}
            >
              Add Brand
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#70a132",
                backgroundColor: "#fff",
                width: 200,
                fontSize: 15,
                height: 30,
                marginRight: 2
              }}
              onClick={openAddCategoryModal}
            >
              Add Food Category
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#70a132",
                backgroundColor: "#fff",
                width: 230,
                fontSize: 15,
                height: 30,
                marginRight: 2
              }}
              onClick={openAddSubCategoryModal}
            >
              Add Food SubCategory
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#70a132",
                backgroundColor: "#fff",
                width: 200,
                fontSize: 15,
                height: 30,
                marginRight: 2
              }}
              onClick={openIngredientAddModal}
            >
              Add Ingredient
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#70a132",
                backgroundColor: "#fff",
                width: 125,
                fontSize: 15,
                height: 30,
                marginRight: 2
              }}
              onClick={fetchProductPreviewData}
            >
              Refresh
            </Button>
          </div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress
                size={50}
                style={{ marginTop: 50, color: "#70a132" }}
              />
            </div>
          ) : products.length > 0 ? (
            <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#70a132",
                  width: 230,
                  fontSize: 15,
                  height: 30,
                  marginRight: 2,
                  marginBottom: 2
                }}
                onClick={submit}
              >
                Save Products
              </Button>
              {storesNotExist.length > 0 && (
                <>
                  <Accordion sx={{ marginBottom: 1 }}>
                    <AccordionSummary sx={{ fontWeight: "bold", color: 'red' }}>
                      {`Please add the following ${storesNotExist.length} store(s) before proceeding:`}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ThemeProvider theme={theme}>
                        <ul>
                          {storesNotExist.map((x, idx) => {
                            return (
                              <li key={idx}>{x}</li>
                            )
                          })}
                        </ul>
                      </ThemeProvider>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              {brandsNotExist.length > 0 && (
                <>
                  <Accordion sx={{ marginBottom: 1 }}>
                    <AccordionSummary sx={{ fontWeight: "bold", color: 'red' }}>
                      {`Please add the following ${brandsNotExist.length} brand(s) before proceeding:`}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ThemeProvider theme={theme}>
                        <ul>
                          {brandsNotExist.map((x, idx) => {
                            return (
                              <li key={idx}>{x}</li>
                            )
                          })}
                        </ul>
                      </ThemeProvider>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              {categoriesNotExist.length > 0 && (
                <>
                  <Accordion sx={{ marginBottom: 1 }}>
                    <AccordionSummary sx={{ fontWeight: "bold", color: 'red' }}>
                      {`Please add the following ${categoriesNotExist.length} food category(s) before proceeding:`}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ThemeProvider theme={theme}>
                        <ul>
                          {categoriesNotExist.map((x, idx) => {
                            return (
                              <li key={idx}>{x}</li>
                            )
                          })}
                        </ul>
                      </ThemeProvider>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              {subcategoriesNotExist.length > 0 && (
                <>
                  <Accordion sx={{ marginBottom: 1 }}>
                    <AccordionSummary sx={{ fontWeight: "bold", color: 'red' }}>
                      {`Please add the following ${subcategoriesNotExist.length} food subCategory(s) before proceeding:`}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ThemeProvider theme={theme}>
                        <ul>
                          {subcategoriesNotExist.map((x, idx) => {
                            return (
                              <li key={idx}>{x}</li>
                            )
                          })}
                        </ul>
                      </ThemeProvider>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              {ingredientsNotExist.length > 0 && (
                <>
                  <Accordion sx={{ marginBottom: 1 }}>
                    <AccordionSummary sx={{ fontWeight: "bold", color: 'red' }}>
                      {`Please add the following ${ingredientsNotExist.length} food ingredient(s) before proceeding:`}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ThemeProvider theme={theme}>
                        <ul>
                          {ingredientsNotExist.map((x, idx) => {
                            return (
                              <li key={idx}>{x}</li>
                            )
                          })}
                        </ul>
                      </ThemeProvider>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              {products.map((x: any, idx: any) => {
                return (
                  <Accordion sx={{ marginBottom: 1 }}>
                    <AccordionSummary sx={{ fontWeight: "600" }}>
                      {x.name}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ThemeProvider theme={theme}>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  sx={{ fontWeight: "bold" }}
                                  align="center"
                                >
                                  Description
                                </TableCell>
                                <TableCell
                                  sx={{ fontWeight: "bold" }}
                                  align="center"
                                >
                                  Brand
                                </TableCell>
                                <TableCell
                                  sx={{ fontWeight: "bold" }}
                                  align="center"
                                >
                                  Food Category
                                </TableCell>
                                <TableCell
                                  sx={{ fontWeight: "bold" }}
                                  align="center"
                                >
                                  Food SubCategory
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell align="center">
                                  {x.description}
                                </TableCell>
                                <TableCell align="center">
                                  <FormControl sx={{ m: 1, minWidth: "80%" }}>
                                    <Select
                                      value={x.brand_id === 0 ? "" : x.brand_id}
                                      onChange={(e) => {
                                        const updatedProducts = [...products]; // Create a copy of the products array
                                        updatedProducts[idx]["brand_id"] =
                                          Number(e.target.value); // Update the specific property
                                        setProducts(updatedProducts); // Update the state with the new array
                                      }}
                                      sx={{
                                        paddingTop: "10px",
                                        "& label": { marginTop: "8px" },
                                        "& label.Mui-focused": {
                                          color: "#70a132",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          "&.Mui-focused fieldset": {
                                            color: "#70a132",
                                          },
                                        },
                                        "&:focus": {
                                          color: "green",
                                        },
                                      }}
                                    >
                                      {allBrands.map((x: any) => (
                                        <MenuItem value={x.id}>
                                          {x.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                  <FormControl sx={{ m: 1, minWidth: "80%" }}>
                                    <Select
                                      value={
                                        x.category_id === 0 ? "" : x.category_id
                                      }
                                      onChange={(e) => {
                                        const updatedProducts = [...products]; // Create a copy of the products array
                                        updatedProducts[idx]["category_id"] =
                                          Number(e.target.value); // Update the specific property
                                        setProducts(updatedProducts); // Update the state with the new array
                                      }}
                                      sx={{
                                        paddingTop: "10px",
                                        "& label": { marginTop: "8px" },
                                        "& label.Mui-focused": {
                                          color: "#70a132",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          "&.Mui-focused fieldset": {
                                            color: "#70a132",
                                          },
                                        },
                                        "&:focus": {
                                          color: "green",
                                        },
                                      }}
                                    >
                                      {allCategories.map((x: any) => (
                                        <MenuItem value={x.id}>
                                          {x.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                  <FormControl sx={{ m: 1, minWidth: "80%" }}>
                                    <Select
                                      value={Number(x.subcategory_id)}
                                      onChange={(e) => {
                                        const updatedProducts = [...products]; // Create a copy of the products array
                                        updatedProducts[idx]["subcategory_id"] =
                                          Number(e.target.value); // Update the specific property
                                        setProducts(updatedProducts); // Update the state with the new array
                                      }}
                                      sx={{
                                        paddingTop: "10px",
                                        "& label": { marginTop: "8px" },
                                        "& label.Mui-focused": {
                                          color: "#70a132",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          "&.Mui-focused fieldset": {
                                            color: "#70a132",
                                          },
                                        },
                                        "&:focus": {
                                          color: "green",
                                        },
                                      }}
                                    >
                                      {allSubCategories
                                        .filter(
                                          (cat: any) =>
                                            parseInt(cat.category_id_id) ===
                                            parseInt(x.category_id)
                                        )
                                        .map((x: any) => (
                                          <MenuItem value={x.id}>
                                            {x.name}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <FormControl
                          sx={{ m: 1, minWidth: "100%", marginLeft: 0 }}
                        >
                          <InputLabel id="test-select-label">Stores</InputLabel>
                          <Select
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                              // label="Chip"
                              />
                            }
                            label='Stores'
                            renderValue={(selected: any) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value: any) => (
                                  <Chip
                                    key={value}
                                    label={getStoreName(value)}
                                  />
                                ))}
                              </Box>
                            )}
                            multiple
                            value={x.stores as any[]}
                            onChange={(e) => handleStoreChange(e, idx)}
                            sx={{
                              paddingTop: "10px",
                              "& label": { marginTop: "8px" },
                            }}
                          >
                            {allStores.map((store: any) => (
                              <MenuItem value={store.id}>
                                <Checkbox
                                  checked={x.stores.indexOf(store.id) > -1}
                                />
                                {store.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl
                          sx={{ m: 1, minWidth: "100%", marginLeft: 0 }}
                        >
                          <Autocomplete
                            multiple
                            id="ingredients"
                            options={allIngredients.map((x: any) => x.name)}
                            value={x.ingredients.map((ingredientId: any) =>
                              getIngredientName(ingredientId)
                            )}
                            onChange={(_, newValue) => {
                              const selectedIngredientIds = newValue.map(
                                (ingredientName: any) =>
                                  allIngredients.find(
                                    (x: any) => x.name === ingredientName
                                  )?.id

                              );
                              handleIngredientsChange(selectedIngredientIds, idx)
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>{option}</li>
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Ingredients"
                                placeholder="Search Ingredients"
                              />
                            )}
                          />

                          {/* DragDrop */}

                          <div>
                            {/* {products.map((x: any) => (x.ingredient))}
                            {x.ingredients.map((ingredientId: any) =>
                              console.log(ingredientId)

                            )} */}

                            {/* {products.map((x: any) => (x.ingredient))}
                            {x.ingredients.map((ingredientId: any) =>
                             <Chip
                             key={x}
                             label={
                               `${ getIngredientName(ingredientId)}`
                             }
                             
                             />
                            )} */}




                            {/* <DragDropContext onDragEnd={(result) => onDragEnd(result,idx)}>
                  <Droppable droppableId="BulkList" type="group" >
                    {(provided) => (<div className={`${products[idx].ingredients.length === 0 ? '' : 'BulkList'}`}  ref={provided.innerRef} {...provided.droppableProps}>
                    {x.ingredients.map((ingredientId:any,index:number) => (
                      <Draggable draggableId={index.toString()} index={index} key={index}>

                      {(provided) => (<div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}><Chip
                       sx={{
                        m:0.5
                      }}
                             key={x}
                             label={
                               `${index+1 + '. ' + getIngredientName(ingredientId)}`
                             }
                             
                             />
                  </div> )}
                   
                  </Draggable>
                  ))}
                  {provided.placeholder}
                  </div>)}
               
                  </Droppable>
                 </DragDropContext> */}


                          </div>

                          {/* <div>
                    {productDetails.ingredients.map((x:any,index:number) => (
                    <Chip
                    key={x}
                    label={
                      `${getIngredientName(x)}`
                    }
                  />
                  ))}
                  </div> */}



                          {/* DragDrop */}



                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: '100%', marginLeft: 0 }}>
                          <InputLabel id="test-select-label">
                            May Contain Ingredients
                          </InputLabel>
                          <Select
                            input={
                              <OutlinedInput id="select-multiple-chip" label="Chip" />
                            }
                            renderValue={(selected) => (
                              <Box
                                sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                              >
                                {(selected || []).map((value: any, idx: any) => (
                                  <Chip
                                    key={value.id}
                                    label={`${getMayContainName(value)}`}
                                  />
                                ))}
                              </Box>
                            )}
                            multiple
                            value={x.mayContain ?? []}
                            onChange={(e) => handleMayContainIngredientChange(e, idx)}
                            labelId="test-select-label"
                            label="May Contains"
                            sx={{
                              paddingTop: "10px",
                              "& label": { marginTop: "8px" },
                            }}
                          >
                            {mayContains.map((y: any) => (
                              <MenuItem value={y} key={y}>
                                <Checkbox
                                  checked={
                                    (x.mayContain ?? []).indexOf(y) > -1
                                  }
                                />
                                {` ${y}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <input type="file" onChange={(e: any) => {
                          const file = e.target.files[0];
                          let p = products;
                          p[idx].image = file
                          p[idx].imageUrl = URL.createObjectURL(file)
                          setProducts(p);
                          console.warn(products)
                        }} />
                        <Box sx={{ display: 'flex', flexBasis: 'row', marginTop: 1 }}>
                          <Typography sx={{ color: 'black', fontWeight: 'bold', marginRight: 1 }}>Please upload the following image:</Typography>{' '}<Typography>{' '}{x.imageName ? x.imageName : 'Image filename not provided'}</Typography>
                        </Box>
                      </ThemeProvider>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          ) : (
            <Box
              sx={{
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  m: 1,
                  p: 0.5,
                  marginTop: 5,
                  fontWeight: 400,
                  fontSize: 20,
                  color: "#111840",
                }}
              >
                No Products Uploaded
              </Typography>
            </Box>
          )}
        </div>
        <Dialog
          open={addModalOpen}
          onClose={() => setAddModalOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Add Excel Data File"}
          </DialogTitle>
          <DialogContent>
            <input type="file" onChange={(e) => handleFileChange(e)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddModalOpen(false)} autoFocus>
              Cancel
            </Button>
            <Button onClick={fetchProductPreviewData} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AddStoreModal open={addStoreModal} onClose={closeAddStoreModal} />
      <AddBrandModal open={addBrandModal} onClose={closeAddBrandModal} />
      <AddCategoryModal open={addCategoryModal} onClose={closeAddCategoryModal} />
      <AddSubCategoryModal open={addSubCategoryModal} onClose={closeAddSubCategoryModal} />
      <AddIngredientModal open={addIngredientModal} onClose={closeIngredientAddModal} />
    </>
  );
}

import React, { Fragment } from 'react';
import './explore.scss';
import { useInView } from 'react-intersection-observer';
import logo from '../../assets/logo/logo.png';
import { Box } from '@mui/material';

interface SliderProps {
    imgSrc: string;
    subtitle: string;
}

const Explore = ({ imgSrc, subtitle }: SliderProps) => {
    const { ref, inView } = useInView({
       
        threshold: 0.2,
    });

    const renderContent = () => {
        return (
            <Box className='slider-box' sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <img src={imgSrc} title='AlleGrow' className='slider__image' />
                <div className='slider__content'>
                    {/* Render subtitle with different font styles */}
                    <p className='slider__txt' dangerouslySetInnerHTML={{ __html: subtitle }} />
                </div>
            </Box>
        );
    };

    return (
        <Fragment>
            <div className={inView ? 'slider slider--zoom' : 'slider'} ref={ref}>
                {renderContent()}
            </div>
        </Fragment>
    );
};

export default Explore;

import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import "./subCategory.scss";
import { AllergyAPI, CategoryAPI, SubCategoryAPI } from '../../api/services';
import { subCategory } from '../../types/subCategory';

interface TextModalProps {
    open: boolean;
    onClose: () => void;
    SubCategoryRow: subCategory;
}

interface MyDropdownProps { label: string; value: number }

const EditModal: React.FC<TextModalProps> = ({ open, onClose, SubCategoryRow } : {open: any, onClose: any, SubCategoryRow: any}) => {
    const [name, setName] = useState<string>('');
    const [contains, setcontains] = useState<string>('');
    const [checked, setChecked] = useState<any>(false);
    const [isAddLoading, setisAddLoading] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState<any>(0);
    const [options, setOptions] = React.useState<MyDropdownProps[]>();
    const [file, setFile] = React.useState(null);
    const [image, setImage] = React.useState<string | undefined>('')

    const handleChange = (event: any) => {
        setChecked(event.target.checked);
    };

    const handleSelectChange = (event: SelectChangeEvent<number>) => {
        setSelectedValue(event.target.value as number);
    };

    const handleSave = async (name: string, contains: string, selectedValue: any) => {
        await setisAddLoading(true);
        if (name === '') {
            toast.error("Name can't be empty!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setisAddLoading(false);
        } else {
            const loggedInId = JSON.parse(String(localStorage.getItem('formData')))["id"];
            // let data = {
            //     "name": name,
            //     "category_id": selectedValue,
            //     "is_deleted": checked,
            //     "updated_by": Number(loggedInId)
            // }
            let formData = new FormData()
            formData.append('name', name)
            formData.append('category_id', selectedValue)
            formData.append('is_deleted', checked)
            formData.append('updated_by', Number(loggedInId).toString())
            if(file !== null){
                formData.append('icon', file)
            }
            SubCategoryAPI.updateSubCategory(formData, SubCategoryRow.id).then((res) => {
                toast.success("Successfully Updated!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setisAddLoading(false);
                onClose()
            }).catch(() => {
                toast.error("Unable to update or SubCategory with same name already exist!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setisAddLoading(false);
            })
        }

    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
        const imageURL = URL.createObjectURL(file);
        setImage(imageURL);
      };

    React.useEffect(() => {
        console.log('Data',SubCategoryRow)
        setName(SubCategoryRow.name);
        setcontains(SubCategoryRow.contains);
        setChecked(SubCategoryRow.is_deleted)
        setSelectedValue(parseInt(SubCategoryRow.category_id_id))
        if(SubCategoryRow.icon !== ''){
            setImage(SubCategoryRow.icon)
        }
    }, [open])

    React.useEffect(() => {
        const templist: { label: string; value: number }[] = new Array();
        CategoryAPI.getAllCategory().then(async (response) => {
            response["data"]
                .sort((a: { name: string }, b: { name: string }) =>
                    a.name.localeCompare(b.name)
                )
                .forEach((element: { name: string; id: number; is_deleted: boolean }) => {
                    if (!element.is_deleted) {
                        templist.push({ label: element.name, value: element.id });
                    }
                });
            setOptions(templist)
        });
    }, [])
    return (
        <Dialog open={open} onClose={onClose} className="text-modal-dialog" style={{ borderRadius: 20 }} >
            <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>Edit Food SubCategory</DialogTitle>
            <DialogContent>
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    InputProps={{
                        disableUnderline: true, // <== added this
                    }}
                    placeholder="Name"
                    className='addText'
                    onChange={(e) => setName(e.currentTarget.value)}
                    value={name}
                    label='SubCategory Name'
                />
                <Select
                    value={parseInt(selectedValue)}
                    onChange={handleSelectChange}
                    displayEmpty={true}
                    inputProps={{ 'aria-label': 'Select an option' }}
                    className='drop-down'
                >
                    <MenuItem value="">Select an option</MenuItem>
                    {options?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            className='checkBox'
                            color='success'
                        />
                    }
                    label="Is InActive"
                    className='checkBox'
                />
                <input style={{width: '100%'}} type="file" onChange={handleFileChange} />
                {image !== '' && (
                    <img style={{marginTop: '20px',width: '100px', height: '100x'}} src={image}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {isAddLoading ?
                    (
                        <Button><CircularProgress size={20} style={{ color: '#70a132' }} /></Button>
                    ) : (
                        <Button onClick={() => {
                            setisAddLoading(true);
                            handleSave(name, contains, selectedValue)
                        }}>Save</Button>
                    )}
            </DialogActions>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Dialog>

    );
};

export default EditModal;

import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, SelectChangeEvent, InputLabel, MenuItem, Select } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import "./symptoms.scss";
import { OrganAPI, SymptomsAPI } from '../../api/services';
import { symptoms } from '../../types/symptoms';

interface TextModalProps {
    open: boolean;
    onClose: () => void;
    symptomsRow: symptoms;
}

interface MyOrganProps { label: string; value: number }

const EditModal: React.FC<TextModalProps> = ({ open, onClose, symptomsRow }) => {
    const [name, setName] = useState<string>('');
    const [checked, setChecked] = useState(false);
    const [isAddLoading, setisAddLoading] = useState(false);
    const [selectedOrgan, setSelectedOrgan] = React.useState(0);
    const [optionsOrgan, setOptionsOrgan] = React.useState<MyOrganProps[]>();

    const handleChange = (event: any) => {
        setChecked(event.target.checked);
    };

    const handleOrganChange = (event: SelectChangeEvent<number>) => {
        setSelectedOrgan(event.target.value as number);
        console.log(event.target.value)
    };

    const handleSave = async (name: string, checked: boolean, selectedOrgan: number) => {
        await setisAddLoading(true);
        if (name === '') {
            toast.error("Name can't be empty!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setisAddLoading(false);
        } else {
            const loggedInId = JSON.parse(String(localStorage.getItem('formData')))["id"];
            let data = {
                "name": name,
                "targated_organ": selectedOrgan,
                "updated_by": Number(loggedInId),
                "is_deleted": checked
            }
            SymptomsAPI.updateSymptoms(data, symptomsRow.id).then((res) => {
                console.log('---------res.data', res.data)
                toast.success("Successfully Updated!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                onClose()
                setisAddLoading(false);
            }).catch(() => {
                toast.error("Unable to update or Symptoms with associated target organ already exist!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setisAddLoading(false);
            })

        }

    };

    React.useEffect(() => {
        const templist: { label: string; value: number }[] = new Array();

        const templist2: { label: string; value: number }[] = new Array();
        OrganAPI.getAllOrgan().then(async (response) => {
            response["data"]
                .sort((a: { name: string }, b: { name: string }) =>
                    a.name.localeCompare(b.name)
                )
                .forEach((element: { name: string; id: number; is_deleted: boolean }) => {
                    if (!element.is_deleted) {
                        templist2.push({ label: element.name, value: element.id });
                    }
                });
            setOptionsOrgan(templist2)
        });
    }, [])

    React.useEffect(() => {
        console.log(symptomsRow)
        setName(symptomsRow.name);
        setChecked(symptomsRow.is_deleted)
        setSelectedOrgan(symptomsRow.targated_organ)
    }, [open])
    return (
        <Dialog open={open} onClose={onClose} className="text-modal-dialog" style={{ borderRadius: 20 }} >
            <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>Edit Symptom</DialogTitle>
            <DialogContent>
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    InputProps={{
                        disableUnderline: true, // <== added this
                    }}
                    placeholder="Name"
                    className='addText'
                    onChange={(e) => setName(e.currentTarget.value)}
                    value={name}
                    label='Symptom Name'
                />
                <InputLabel style={{ marginTop: 10 }}>Select an Organ</InputLabel>
                <Select
                    value={selectedOrgan}
                    onChange={handleOrganChange}
                    displayEmpty={true}
                    inputProps={{ 'aria-label': 'Select an option' }}
                    className='drop-down'
                >
                    {optionsOrgan?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            className='checkBox'
                            color='success'
                        />
                    }
                    label="Is InActive"
                    className='checkBox'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {isAddLoading ?
                    (
                        <Button><CircularProgress size={20} style={{ color: '#70a132' }} /></Button>
                    ) : (
                        <Button onClick={() => {
                            setisAddLoading(true);
                            handleSave(name, checked, selectedOrgan)
                        }}>Save</Button>
                    )}
            </DialogActions>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Dialog>

    );
};

export default EditModal;

import React from "react";
import "./bottomExplore.scss";
import A from "../../assets/images/A - allegrow logo.png";
import approved from "../../assets/logo/AFSA Seal of Approval-04.png";
import "./explore.scss";
import { useInView } from "react-intersection-observer";

interface BottomProps {
  website: string;
}

const BottomExplore = ({ website }: BottomProps) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const email = "support@allegrow.co.za";

  return (
    <div className={`bottom ${inView ? "slider--zoom" : ""} explore-bottom`} ref={ref}>
      <img src={A} alt="" className="bottom__logo" />
      <p className="bottom__text">
        <span className="email">{email}</span>
        <br />
        <a href={website} target="_blank" rel="noopener noreferrer">
          {website}
        </a>
      </p>
      <img src={approved} alt="" className="bottom__image" />
    </div>
  );
};

export default BottomExplore;

import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, MenuItem, Select, SelectChangeEvent, Chip, InputLabel } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import "./ingredient.scss";
import { AllergyAPI, IngredientAPI, IngredientContainAPI } from '../../api/services';
import { ingredient } from '../../types/ingredient';

interface TextModalProps {
    open: boolean;
    onClose: () => void;
    ingredientRow: any;
    options: [];
    getAllIngredients: () => void;
}
interface Allergy {
    id: number;
    name: string;
    notes: string;
    contains: string;
    others: boolean;
    created_date: string;
    modified_date: string;
    is_deleted: boolean;
    created_by: number;
    updated_by: number;
    child_id: number | null; // Assuming it can be null
}

interface Option {
    contains: string;
    allergy: Allergy[];
}

interface MyDropdownProps { label: string; value: number }

const EditModal: React.FC<TextModalProps> = ({ open, onClose, ingredientRow, options,getAllIngredients }) => {
    const [name, setName] = useState<string>('');
    const [contains, setcontains] = useState<string>('');
    const [checked, setChecked] = useState(false);
    const [isAddLoading, setisAddLoading] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState(0);
    // const [options, setOptions] = React.useState<string[]>([]);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        setSelectedValues(event.target.value as string[]);
    };

    const handleSelectChange = (event: SelectChangeEvent<number>) => {
        setSelectedValue(event.target.value as number);
    };

    const handleSave = async (name: string, contains: string, selectedValue: number) => {
        await setisAddLoading(true);
        if (name === '') {
            toast.error("Name can't be empty!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setisAddLoading(false);
        } else {
            const loggedInId = JSON.parse(String(localStorage.getItem('formData')))["id"];
            let data = {
                "name": name,
                "is_deleted": checked,
                "updated_by": Number(loggedInId)
            }
            IngredientAPI.updateIngredient(data, ingredientRow.id).then((res) => {
                IngredientContainAPI.updateIngredientContain(ingredientRow.id, selectedValues).then((res: any) => {
                })
                console.warn(res.data)
                toast.success("Successfully Updated!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setisAddLoading(false);
                setSelectedValues([])
                onClose()
                getAllIngredients()
            }).catch(() => {
                toast.error("Unable to update or Ingredient with same name already exist!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setisAddLoading(false);
            })
        }

    };

    React.useEffect(() => {
        setName(ingredientRow.name);
        setcontains(ingredientRow.contains);
        setChecked(ingredientRow.is_deleted)
        setSelectedValue(ingredientRow.associated_allergy)
        // IngredientContainAPI.getIngredientContain(ingredientRow.id).then((res) => {
        //     let temp: any[] = []
        //     res.data.forEach((value: any) => {
        //         temp.push(value.contains)
        //     })
        if (String(ingredientRow.may_contain) == 'undefined' || String(ingredientRow.may_contain).length == 0) {
            setSelectedValues([])
            
        } else {
            let tempval = String(ingredientRow.may_contain).split(',')
            const trimmedArray = tempval.map(item => item.trim());
            setSelectedValues(trimmedArray)
            console.warn(trimmedArray)
        }
        // })
    }, [open])

    // React.useEffect(() => {
    //     AllergyAPI.getAllergyContains().then(async (response) => {
    //         // setOptions(response.data)
    //         console.warn("Edit ", response.data)
    //     });
    // }, [])
    return (
        <Dialog open={open} onClose={() => {
            setSelectedValues([])
            onClose()
            getAllIngredients()
        }} className="text-modal-dialog" style={{ borderRadius: 20 }} >
            <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>Edit Ingredients</DialogTitle>
            <DialogContent>
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    InputProps={{
                        disableUnderline: true, // <== added this
                    }}
                    placeholder="Name"
                    className='addText'
                    onChange={(e) => setName(e.currentTarget.value)}
                    value={name}
                    label='Ingredient Name'
                />
                <InputLabel style={{ marginTop: 10 }}>Select a Contains</InputLabel>
                <Select
                    multiple
                    value={selectedValues}
                    onChange={handleChange}
                    displayEmpty={true}
                    inputProps={{ 'aria-label': 'Select options' }}
                    className='drop-down'
                    renderValue={(selected) => (
                        <div>
                            {selected.map((value: any) => (
                                <Chip key={value} label={value} />
                            ))}
                        </div>
                    )}
                >
                    {options?.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox
                                checked={
                                    selectedValues.indexOf(option) > -1
                                }
                            />
                            {option}
                        </MenuItem>
                    ))}
                </Select>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            className='checkBox'
                            color='success'
                        />
                    }
                    label="Is  InActive"
                    className='checkBox'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setSelectedValues([])
                    onClose()
                    getAllIngredients()
                }}>Cancel</Button>
                {isAddLoading ?
                    (
                        <Button><CircularProgress size={20} style={{ color: '#70a132' }} /></Button>
                    ) : (
                        <Button onClick={() => {
                            setisAddLoading(true);
                            handleSave(name, contains, selectedValue)
                        }}>Save</Button>
                    )}
            </DialogActions>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Dialog>

    );
};

export default EditModal;
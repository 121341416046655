import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, InputLabel, Chip, Checkbox } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import "./ingredient.scss";
import { AllergyAPI, IngredientAPI, IngredientContainAPI } from '../../api/services';

interface TextModalProps {
    open: boolean;
    onClose: () => void;
}

interface Allergy {
    id: number;
    name: string;
    notes: string;
    contains: string;
    others: boolean;
    created_date: string;
    modified_date: string;
    is_deleted: boolean;
    created_by: number;
    updated_by: number;
    child_id: number | null; // Assuming it can be null
}

interface Option {
    contains: string;
}

interface MyDropdownProps { label: string; value: number }

const AddIngredientModal: React.FC<TextModalProps> = ({ open, onClose }) => {
    const [name, setName] = useState<string>('');
    const [contains, setContains] = useState<string>('');
    const [isAddLoading, setisAddLoading] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState(0);
    const [options, setOptions] = React.useState<string[]>([]);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        setSelectedValues(event.target.value as string[]);
        console.log(event.target);
    };


    const handleSave = (name: string, contains: string, selectedValue: number) => {
        if (name === '') {
            toast.error("Name can't be empty!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setisAddLoading(false);
        } else {
            const loggedInId = JSON.parse(String(localStorage.getItem('formData')))["id"];
            let data = {
                "name": name,
                "created_by": Number(loggedInId),
                "updated_by": Number(loggedInId)
            }
            IngredientAPI.addIngredient(data).then((res) => {
                let temp: { ingredient_id: any; contains: any; }[] = []
                selectedValues.forEach((index: any) => {
                    console.warn(index)
                    temp.push({ "ingredient_id": res.data.id, "contains": index })
                })
                IngredientContainAPI.addIngredientContain(temp).then((res) => {
                    console.warn(res.data)
                })
                setName('');
                setContains('');
                setSelectedValue(0);
                setisAddLoading(false);
                toast.success("Ingredient Added Successfully!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                onClose();
            }).catch(() => {
                toast.error("Ingredient already exist!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setisAddLoading(false);
            })
        }

    };

    React.useEffect(() => {
        const templist: { label: string; value: number }[] = new Array();
        AllergyAPI.getAllergyContains().then(async (response) => {
            console.warn(response.data[1])
            setOptions(response.data)
        });
    }, [])
    return (
        <Dialog open={open} onClose={onClose} className="text-modal-dialog" style={{ borderRadius: 20 }}>
            <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>Add Ingredient</DialogTitle>
            <DialogContent>
                <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    InputProps={{
                        disableUnderline: true, // <== added this
                    }}
                    placeholder="Name"
                    className='addText'
                    onChange={(e) => setName(e.currentTarget.value)}
                    value={name}
                />
                <InputLabel style={{ marginTop: 10 }}>Select a Contains</InputLabel>
                <Select
                    multiple
                    value={selectedValues}
                    onChange={handleChange}
                    displayEmpty={true}
                    inputProps={{ 'aria-label': 'Select options' }}
                    className='drop-down'
                    renderValue={(selected) => (
                        <div>
                            {selected.map((value: any) => (
                                <Chip key={value} label={value} />
                            ))}
                        </div>
                    )}
                >
                    {options?.map((option) => (
                            <MenuItem key={option} value={option}>
                                <Checkbox
                                    checked={
                                        selectedValues.indexOf(option) > -1
                                    }
                                />
                                {option}
                            </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {isAddLoading ?
                    (
                        <Button><CircularProgress size={20} style={{ color: '#70a132' }} /></Button>
                    ) : (
                        <Button onClick={() => {
                            setisAddLoading(true);
                            handleSave(name, contains, selectedValue)
                        }}>Save</Button>
                    )}
            </DialogActions>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Dialog >

    );
};

export default AddIngredientModal;

import * as React from "react";
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import {
  DataGrid,
} from "@mui/x-data-grid";
import SideNavigation from "../../navigation/SideNavigation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import { url } from "../../api/constants";
import axios from "axios";

export default function SendEmail() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  const [subject, setSubject] = React.useState('');
const [message, setMessage] = React.useState('');
const [files, setFiles] = React.useState<any>([]);
const [error, setError] = React.useState<any>(null);

    // Handle form data submission
    const handleSubmit = async (event:any) => {
        event.preventDefault();

        // Validate required fields
        if (!subject || !message) {
            setError("Subject and Message are required");
            return;
        }
        setIsLoading(true)

        const formData = new FormData();
        formData.append('subject', subject);
        formData.append('message', message);

        // Append files to formData if any
        files.forEach((file:any) => {
            formData.append('attachments', file);
        });

        try {
            // Replace with your Django API endpoint

            const response = await axios.post(`${url}users/send_email/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',  // For file uploads
                }
            });

            console.log('EMAIL RESPONSE', response)
                setIsLoading(false)
                toast.success("Email Sent!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setSubject('');
                setMessage('');
                setFiles([]);
        } catch (error) {
            setIsLoading(false)
            toast.error("An error occurred while sending the email", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const handleFileChange = (event: any) => {
        setFiles([...event.target.files]);
    };

  return (
    <>
      <div id="home-container">
        <Helmet>
          <title>{'AlleGrow-Send Email'}</title>
        </Helmet>
        <div>
          <SideNavigation />
        </div>
        <div className="child">
        <div className="header">
            <p style={{ fontSize: 25, fontWeight: 500, color: "#70a132", marginTop: 80  }}>Send Email</p></div>
          {isLoading ?
            (
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <CircularProgress size={50} style={{ marginTop: 50, color: '#70a132' }} />
              </div>
            ) : (<div style={{ width: "100%" }}>
                {error && <Typography color="error">{error}</Typography>}
            
                <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {/* Subject Field */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                            error={!subject && error}
                            helperText={!subject && error ? 'This field is required' : ''}
                            color="success"
                            sx={{
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'green',
                                },
                                '& .MuiOutlinedInput-root.Mui-focused': {
                                    borderColor: 'green',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: subject ? 'green' : '', // Conditional border color
                                    },
                                },
                            }}
                        />
                    </Grid>

                    {/* Message Field */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Message"
                            multiline
                            rows={20}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            error={!message && error}
                            helperText={!message && error ? 'This field is required' : ''}
                            color="success"
                            sx={{
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'green',
                                },
                                '& .MuiOutlinedInput-root.Mui-focused': {
                                    borderColor: 'green',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: subject ? 'green' : '', // Conditional border color
                                    },
                                },
                            }}
                        />
                    </Grid>

                    {/* File Upload Field */}
                    <Grid item xs={12}>
                        <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            style={{ width: '100%' }}
                        />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12}>
                        <Button
                        sx={{backgroundColor: '#70a132'}}
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Send Email
                        </Button>
                    </Grid>
                </Grid>
            </form>
            
            </div>)}
        </div>
      </div>
      <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
    </>
  );
}
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./users.scss";
import { CategoryAPI, SubCategoryAPI, UsersAPI } from "../../api/services";

interface TextModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface MyDropdownProps {
  label: string;
  value: number;
}

const AddUserModal: React.FC<TextModalProps> = ({ open, onClose, onSuccess }) => {
  const [name, setName] = useState<string>("");
  const [isAddLoading, setisAddLoading] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState(0);
  const [options, setOptions] = React.useState<MyDropdownProps[]>();
  const [user, setUser] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    gender: "",
    provincial_address: "",
    password: "",
    role: '0',
    profession: "",
    phone: "",
    is_admin: false,
  });

  const handleChange = (event: SelectChangeEvent<number>) => {
    setSelectedValue(event.target.value as number);
    console.log(event.target.value);
  };

  const professionType = [
    { label: "Allergist", value: "Allergist" },
    { label: "Dietitian", value: "Dietitian" },
    { label: "Immunologist", value: "Immunologist" },
    { label: "Medical Doctor (General Practitioner)", value: "Medical Doctor (General Practitioner)" },
    { label: "Occupational Therapist", value: "Occupational Therapist" },
    { label: "Paediatrician", value: "Paediatrician" },
    { label: "Physiotherapist", value: "Physiotherapist" },
    { label: "Psychologist", value: "Psychologist" },
    { label: "Registered Nurse", value: "Registered Nurse" },
    { label: "Specialist Physician", value: "Specialist Physician" },
    { label: "Speech Therapist", value: "Speech Therapist" },
    { label: "Other", value: "Other" },
  ];

  const accountType_Data = [
    {
      label: "Living with Food Allergies/ Intolerences",
      value: "0",
    },
    { label: "A Healthcare Practitioner", value: "1" },
    { label: "Other", value: "2" },
  ];
  const province_Data = [
    { label: "Eastern Cape", value: "Eastern Cape" },
    { label: "Free State", value: "Free State" },
    { label: "Gauteng", value: "Gauteng" },
    { label: "KwaZulu-Natal", value: "KwaZulu-Natal" },
    { label: "Limpopo", value: "Limpopo" },
    { label: "Mpumalanga", value: "Mpumalanga" },
    { label: "Northern Cape", value: "Northern Cape" },
    { label: "North West", value: "North West" },
    { label: "Western Cape", value: "Western Cape" },
  ];

  const handleSave = () => {
    UsersAPI.addUser(user).then(() => {
      onSuccess();
      onClose();
    })
  };

  React.useEffect(() => {
    const templist: { label: string; value: number }[] = new Array();
    CategoryAPI.getAllCategory().then(async (response) => {
      response["data"]
        .sort((a: { name: string }, b: { name: string }) =>
          a.name.localeCompare(b.name)
        )
        .forEach(
          (element: { name: string; id: number; is_deleted: boolean }) => {
            if (!element.is_deleted) {
              templist.push({ label: element.name, value: element.id });
            }
          }
        );
      setOptions(templist);
    });
  }, []);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="text-modal-dialog"
      style={{ borderRadius: 20 }}
    >
      <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>
        Add User - Please fill in all fields
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          id="name"
          name="name"
          InputProps={{
            disableUnderline: true, // <== added this
          }}
          placeholder="Name"
          className="addText"
          onChange={(e) => setUser({...user, first_name: e.currentTarget.value})}
          value={user.first_name}
          label="Name"
        />
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          InputProps={{
            disableUnderline: true, // <== added this
          }}
          placeholder="Surname"
          className="addText"
          onChange={(e) => setUser({...user, last_name: e.currentTarget.value})}
          value={user.last_name}
          label="Surname"
        />
        <TextField
          variant="standard"
          type="email"
          margin="normal"
          required
          fullWidth
          InputProps={{
            disableUnderline: true, // <== added this
          }}
          placeholder="Email"
          className="addText"
          onChange={(e) => setUser({...user, email: e.currentTarget.value})}
          value={user.email}
          label="Email"
        />
        <TextField
          variant="standard"
          type="tel"
          margin="normal"
          required
          fullWidth
          InputProps={{
            disableUnderline: true, // <== added this
          }}
          placeholder="Phone"
          className="addText"
          onChange={(e) => setUser({...user, phone: e.currentTarget.value})}
          value={user.phone}
          label="Phone"
        />
        <InputLabel style={{ marginTop: 10 }}>Gender</InputLabel>
        <Select
          value={user.gender}
          onChange={(e: any) => setUser({...user, gender: e.target.value})}
          displayEmpty={true}
          inputProps={{ "aria-label": "Select an option" }}
          className="drop-down"
        >
            <MenuItem value={'Male'}>
              {'Male'}
            </MenuItem>
            <MenuItem value={'Female'}>
              {'Female'}
            </MenuItem>
        </Select>
        <InputLabel style={{ marginTop: 10 }}>Province</InputLabel>
        <Select
          value={user.provincial_address}
          onChange={(e: any) => setUser({...user, provincial_address: e.target.value})}
          displayEmpty={true}
          inputProps={{ "aria-label": "Select an option" }}
          className="drop-down"
        >
          {province_Data.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <InputLabel style={{ marginTop: 10 }}>User Type</InputLabel>
        <Select
          value={user.role}
          onChange={(e: any) => setUser({...user, role: e.target.value})}
          displayEmpty={true}
          inputProps={{ "aria-label": "Select an option" }}
          className="drop-down"
        >
          {accountType_Data.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {user.role === '1' && (
            <>
                <InputLabel style={{ marginTop: 10 }}>Profession</InputLabel>
        <Select
          value={user.profession}
          onChange={(e: any) => setUser({...user, profession: e.target.value})}
          displayEmpty={true}
          inputProps={{ "aria-label": "Select an option" }}
          className="drop-down"
        >
          <MenuItem value="">Select an option</MenuItem>
          {professionType.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
            </>
        )}
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          InputProps={{
            disableUnderline: true, // <== added this
          }}
          placeholder="Password"
          className="addText"
          onChange={(e) => setUser({...user, password: e.target.value})}
          value={user.password}
          label="Password"
        />
        <FormControlLabel
                    control={
                        <Checkbox
                            checked={user.is_admin}
                            onChange={() => setUser({...user, is_admin: !user.is_admin})}
                            className='checkBox'
                            color='success'
                        />
                    }
                    label="Is Admin"
                    className='checkBox'
                />
      </DialogContent>
      <DialogActions>
        {isAddLoading ? (
          <Button>
            <CircularProgress size={20} style={{ color: "#70a132" }} />
          </Button>
        ) : (
          <>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={() => {
                setisAddLoading(true);
                handleSave();
              }}
            >
              Save
            </Button>
          </>
        )}
      </DialogActions>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Dialog>
  );
};

export default AddUserModal;

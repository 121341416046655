import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./allergy.scss";
import { AllergyAPI } from '../../api/services';

interface TextModalProps {
    open: boolean;
    onClose: () => void;
}

const TextModal: React.FC<TextModalProps> = ({ open, onClose }) => {
    const [name, setName] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [contains, setContains] = useState<string>('');
    const [isAddLoading, setisAddLoading] = useState(false);

    const handleSave = (name: string, notes: string) => {
        if (name === '') {
            toast.error("Name can't be empty!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setisAddLoading(false);
        } else {
            const loggedInId = JSON.parse(String(localStorage.getItem('formData')))["id"];
            let data = {
                "name": name,
                "notes": notes,
                "contains": contains,
                "created_by": Number(loggedInId),
                "updated_by": Number(loggedInId)
            }
            AllergyAPI.addAllergy(data).then((res) => {
                setName('');
                setNotes('');
                setContains('');
            setisAddLoading(false);
            toast.success("Allergy Added Successfully!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            onClose()
        }).catch ((error) => {
            toast.error("Allergy already exist!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setisAddLoading(false);
        })
    }

};

return (
    <Dialog open={open} onClose={onClose} className="text-modal-dialog" style={{ borderRadius: 20 }}>
        <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>Add Allergy</DialogTitle>
        <DialogContent>
            <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="name"
                name="name"
                InputProps={{
                    disableUnderline: true, // <== added this
                }}
                placeholder="Name"
                className='addText'
                onChange={(e) => setName(e.currentTarget.value)}
                value={name}
            />
            <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="notes"
                name="notes"
                InputProps={{
                    disableUnderline: true, // <== added this
                }}
                placeholder="Notes"
                className='addText'
                // multiline={true}
                // maxRows={5}
                onChange={(e) => setNotes(e.currentTarget.value)}
                value={notes}
            />
            <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="contains"
                name="contains"
                InputProps={{
                    disableUnderline: true, // <== added this
                }}
                placeholder="Contains"
                className='addText'
                // multiline={true}
                // maxRows={5}
                onChange={(e) => setContains(e.currentTarget.value)}
                value={contains}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            {isAddLoading ?
                (
                    <Button ><CircularProgress size={20} style={{ color: '#70a132' }} /></Button>
                ) : (
                    <Button onClick={() => {
                        setisAddLoading(true);
                        handleSave(name, notes)
                    }}>Save</Button>
                )}
        </DialogActions>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    </Dialog>

);
};

export default TextModal;
